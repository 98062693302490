import { render, staticRenderFns } from "./videoList1.vue?vue&type=template&id=4088c4b1&scoped=true&"
import script from "./videoList1.vue?vue&type=script&lang=js&"
export * from "./videoList1.vue?vue&type=script&lang=js&"
import style0 from "./videoList1.vue?vue&type=style&index=0&id=4088c4b1&scoped=true&lang=stylus&"
import style1 from "./videoList1.vue?vue&type=style&index=1&id=4088c4b1&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4088c4b1",
  null
  
)

export default component.exports