import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import twoStage from '../components/twoStage/index.vue'
import m_product from '../components/m-product/index.vue'
import m_video from '../components/m-video/index.vue'
import m_videoInfo from '../components/m-video/indexTwo.vue'
import m_join from '../components/m-join/index.vue'
import m_aboutus from '../components/m-aboutus/index.vue'
import videoList1 from '../components/videoList/videoList.vue'
import videoList from '../components/videoList/videoList.vue'
import videoList2 from '../components/videoList/videoList1.vue'
import m_indexList from '../components/m-indexList/index.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/twoStage',
    name: 'twoStage',
    component: twoStage
  },{
    path: '/m_product',
    name: 'm_product',
    component: m_product
  },{
    path: '/m_video',
    name: 'm_video',
    component: m_video
  },{
    path: '/m_videoInfo',
    name: 'm_videoInfo',
    component: m_videoInfo
  },{
    path: '/m_join',
    name: 'm_join',
    component: m_join
  },{
    path: '/m_aboutus',
    name: 'm_aboutus',
    component: m_aboutus
  },{
    path: '/videoList1',
    name: 'videoList1',
    component: videoList1
  },{
    path: '/videoList2',
    name: 'videoList2',
    component: videoList2
  },{
    path: '/m_indexList',
    name: 'm_indexList',
    component: m_indexList
  },{
    path: '/videoList',
    name: 'videoList',
    component: videoList
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	document.scrollingElement.scrollTop = 0
})
export default router
