<template>
  <div class="product_main">
    <div class="topList">
      <div class="topListItem"  :class="arrStatus[0]? 'showTopListItem':''" @click="setShowItem(0)">
        <div class="orderNumber">1</div>
        <div class="orderNumberName">安全数智管理平台</div>
        <div class="orderText">提供企业级项目施工全过程安全数智化监管服务，包含工程信息数智化，施工计划管理，
          标准安全防护措施，风险动态管控及预警，现场人员/机械设备及车辆管控等，全方位提升项目工程安全信息化。</div>
        <div class="showLines" v-if="arrStatus[0]"></div>
      </div>
       <!-- <div class="topListItem" :class="arrStatus[1]? 'showTopListItem':''"  @click="setShowItem(1)">
        <div class="orderNumber">2</div>
        <div class="orderNumberName">改扩建智慧保通平台</div>
        <div class="orderText">安全数智管理平台安全数智管理平台内容详情安全数智管理平台内容详情安全数智管理平台。</div>
        <div class="showLines" v-if="arrStatus[1]"></div>
      </div>

       <div class="topListItem" :class="arrStatus[2]? 'showTopListItem':''"  @click="setShowItem(2)">
        <div class="orderNumber">3</div>
        <div class="orderNumberName">双重预防管理平台</div>
        <div class="orderText">安全数智管理平台安全数智管理平台内容详情安全数智管理平台内容详情安全数智管理平台。</div>
        <div class="showLines" v-if="arrStatus[2]"></div>
      </div> -->

       <div class="topListItem" :class="arrStatus[1]? 'showTopListItem':''"  @click="setShowItem(1)">
        <div class="orderNumber">2</div>
        <div class="orderNumberName">双重预防管理平台</div>
        <div class="orderText">结合施工计划主动识别风险并及时预警，风险处理落实到人，闭环流程处理机制。形成电子档案及汇报材料，实现管理留痕可追溯。
          系统内置8000余条隐患处理措施，保障处理人员快速妥善进行问题处理。实现安全隐患治理“信息化” “可视化” “数字化” “清单化” “闭环化” 。</div>
        <div class="showLines" v-if="arrStatus[1]"></div>
      </div>

       <div class="topListItem" :class="arrStatus[2]? 'showTopListItem':''"  @click="setShowItem(2)">
        <div class="orderNumber">3</div>
        <div class="orderNumberName">人员管理及教育平台</div>
        <div class="orderText">人员管理结合安全培训，以信息数字化手段实现对施工人员的日常工作管控，将安全生产落实到每一名一线作业人员。
          系统内置庞大的视频课件及试卷库，包含三级教育，班前会，日常安全培训，覆盖各类工种。所有人员信息，学习及考试台账均可下载，线下归档留存。</div>
        <div class="showLines" v-if="arrStatus[2]"></div>
      </div>
    </div>

    <div class="numberOne">
      <div class="leftImg">
        <img :src="showSelect.url" alt="">
      </div>
      <div class="rightText">
        <div class="show">
          <div class="title">
            <div class="titleleft_Text">{{showSelect.titleFirst}}</div>
            <div class="titleleft_TextTpis">{{showSelect.titleDec}}</div>
          </div>
          <div class="showIcon">
            <div class="duan"></div>
            <div class="chang"></div>
          </div>
        </div>
        <div class="showDec">
          <div class="showdecText">{{showSelect.titleDeatil}}</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>



export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {

    },
  data(){
        return{
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            setectPrice:1,
            arrStatus:[true,false,false,false,false],
            showSelect:{},
            showAll:[{
              url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/device.png',
              titleFirst:'产品介绍',
              titleDec:"安全数智管理平台",
              titleDeatil:'提供企业级项目施工全过程安全数智化监管服务，包含工程信息数智化，施工计划管理，标准安全防护措施，风险动态管控及预警，现场人员/机械设备及车辆管控等，全方位提升项目工程安全信息化。'
            },{
              url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/url.png',
              titleFirst:'产品介绍',
              titleDec:"双重预防管理平台",
              titleDeatil:'结合施工计划主动识别风险并及时预警，风险处理落实到人，闭环流程处理机制。形成电子档案及汇报材料，实现管理留痕可追溯。系统内置8000余条隐患处理措施，保障处理人员快速妥善进行问题处理。实现安全隐患治理“信息化” “可视化” “数字化” “清单化” “闭环化” 。'
            },{
              url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/urls.png',
              titleFirst:'产品介绍',
              titleDec:"人员管理及教育平台",
              titleDeatil:'人员管理结合安全培训，以信息数字化手段实现对施工人员的日常工作管控，将安全生产落实到每一名一线作业人员。系统内置庞大的视频课件及试卷库，包含三级教育，班前会，日常安全培训，覆盖各类工种。所有人员信息，学习及考试台账均可下载，线下归档留存。'
            }],
        }
  },
   mounted() {
    let that=this
    this.showSelect=this.showAll[0]

    },
    methods:{
      setShowItem(e){
       for (const key in this.arrStatus) {
          this.arrStatus[key]=false
       }
       this.arrStatus[e]=true
       this.showSelect=this.showAll[e]
       this.$forceUpdate()
      }
    },

}

</script>

<style scoped lang="stylus">
.topList
  display: flex
  padding-top:40px ;
  justify-content: space-between;
  width 1500px
  margin: 0 auto;
  .topListItem
    width: 400px;
    height: 240px;
    background: #FFFFFF;
    padding: 0 6px;
    padding-top 40px
    cursor pointer
    .orderNumber
      width: 58px;
      height: 58px;
      background: #FFFFFF;
      border-radius: 50%;
      line-height: 58px;
      box-shadow: inset 0px -1px 3px 0px rgba(194,208,221,0.29), inset 0px 2px 2px 0px #FFFFFF;
      margin: 0 auto;
      box-sizing: border-box;
      font-size: 30px;
      font-weight: normal;
      color: #333333;

    .orderNumberName
      font-size: 20px;
      color: #333333;
      font-weight 600
    .orderText
      width: 366px;
      font-size: 15px;
      color: #666666;
      margin: 0 auto;
      text-align: center;
      padding-top 30px
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    .showLines
      width: 70px;
      height: 5px;
      background: #0173F3;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 27px;
.leftImg   
  width 960px
  height  650px
  img
    width 100%
    height  100%
.numberOne
  display: flex;
  justify-content: flex-start
  padding-top 26px
  .rightText
    width 510px
    padding-left  244px
    text-align: left
    padding-top 116px

.show
  display: flex;
  justify-content: space-between;

.titleleft_Text
  font-size: 50px;
  color: #333333;
  font-weight: 600
.titleleft_TextTpis
  font-size: 30px;
  color: #333333;
  font-weight: 600
  padding-top 20px
.showdecText
  padding-top 30px
  font-size: 15px;
  color: #666666;
  line-height 50px

.showTopListItem
  box-shadow: 0px 5px 15px 0px rgba(0,96,204,0.1);

.showIcon
  display: flex
  justify-content: center;
  align-items top 
  .duan
    width: 5px;
    height: 34px;
    background: #0173F3;
    margin-right  3px
  .chang  
    width: 12px;
    height: 78px;
    border: 2px solid #0173F3;
</style>
