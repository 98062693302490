<template>
  <div class="content videoPupop">
    <div class="pcVideoList" v-if="isPc">
      <div class="toptitle">
          <div class="toptitleF">
            <div class="showTitleText">工程资讯</div>
          </div>
      </div>

      <div class="LabelList">
        <div class="labelItem" @click="setLabelVideo(index)" :class="selectLabelStatus[index]? 'activeLabel':''" v-for="(item,index) in labels" :key="index">{{item}}</div>
      </div>
      <div class="showImgList">
          <div class="showImgItem" v-for="(item,index) in videoList" :key="index" @click="setShowVideo(index)">
            <div class="showItemImgUrl"><img :src="item.videoImg" alt=""></div>
            <div class="showTitle">
              <div>{{item.title}}</div>
            </div>
          </div>
      </div>

      <van-popup v-model="show" @close="closes" position="center" closeable >
        <div class="showLeftPupop">
            <div v-for="(item,index) in videoList" :key="index">
              <div class="showVider" v-if="item.showStatus">
                  <video id="audioPlayer" poster=""
                    :src="item.videoUrl"
                    autoplay="autoplay" 
                    controls
                    loop="loop"
                    ref="audioPlayer"
                      >
                    <source src="movie.mp4" type="video/mp4">
                    <source src="movie.ogg" type="video/ogg">
                  </video>
              </div>
            </div>
        </div>
      </van-popup>

    </div>
    <!-- h5VideoList -->
    <div class="mVideoList"  v-if="!isPc">
      <div class="mobileMain">
        <div class="mproductList">
            <div class="videoOrImg">
                <div class="mshowTitleName">工程资讯</div>
                <div class="mLabelList">
                  <div class="mlabelItem" @click="setLabelVideo(index)" :class="selectLabelStatus[index]? 'mactiveLabel':''" v-for="(item,index) in labels" :key="index">{{item}}</div>
                  
                </div>
                <div class="mshowImgList">
                    <div class="mshowImgItem" @click="setmShowVideo(index)" v-for="(item,index) in videoList" :key="index">
                        <div class="mshowImgUrl"><img :src="item.videoImg" alt=""></div>
                        <div class="mshowName">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>

      <van-popup v-model="showModleVideo" @close="closes" closeable position="center"  >
        <div class="mshowLeftPupop">
          <div v-for="(item,index) in videoList" :key="index">
            <div class="mshowVider" v-if="item.showStatus">
              <video id="audioPlayer" poster=""
                :src="item.videoUrl"
                autoplay="autoplay" 
                controls
                loop="loop"
                ref="audioPlayer"
                >
              <source src="movie.mp4" type="video/mp4">
              <source src="movie.ogg" type="video/ogg">
              </video>
            </div>
          </div>
        </div>
      </van-popup>

      </div>


    </div>
  </div>
</template>


<script>
// // @ is an alias to /src
// import concent from '@/components/index.vue'
// import product from '@/components/product.vue'


export default {
  name: 'Home',

  components: {
    
  },
  data(){
      return{
        run:true,
        pageNum:3,
        showActiver:1,
        show:false,
        showVide:false,
        showItem:0,
        isPc:false,
        showModleVideo:false,
        LabelLists:[],
        videoList:[{}],
        labels:[],
        selectLabelStatus:[],
        videoListALL:[]
      }
  },
  methods:{
    selectActiver(num){
      let that=this
      that.showActiver=num
      this.$router.push({name:'Home',params:{type:num}})
    },
    setShowVideo(e){
      for (const key in this.videoList) {
        this.videoList[key].showStatus=false
      }
      this.show=true
      this.videoList[e].showStatus=true
    },
    closes(){
      // console.log(222222)
      this.$refs.audioPlayer[0].pause()
    },
    setmShowVideo(e){
      for (const key in this.videoList) {
        this.videoList[key].showStatus=false
      }
      this.showModleVideo=true
      this.videoList[e].showStatus=true
    },
    jsonHttpTest:async function(){
        // 放在src中的文件都会被webpack根据依赖编译，无法作为路径使用，static中的文件才可以作为路径用
        // const res = await this.axios.get('https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/videoData%E3%80%90test-videolist2%E3%80%91.json');
        console.log(window.location.origin)
        // if(window.location.origin=='https://test.anzhique.com'){
        
        // }
        if(window.location.origin=='https://www.anzhique.com'){
          const res = await this.axios.get('https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/videoData%E3%80%90prod-videolist2%E3%80%91.json');
           if(res.data.data.length>0){
            this.videoListALL=res.data.data
            let label=[]
                label[0]='全部'
            for (const key in res.data.labels) {
            label.push(res.data.labels[key])
            }
            this.videoList=[]
            for (const key in res.data.data) {
              res.data.data[key].showStatus=false
            }

            this.videoList=res.data.data
            for (const key in label) {
            this.selectLabelStatus[key]=false
            }
            
            this.selectLabelStatus[0]=true
            this.labels=label
            
              // console.log(this.videoList)
          }
        }else{
          const res = await this.axios.get('https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/videoData%E3%80%90test-videolist2%E3%80%91.json');
           if(res.data.data.length>0){
            this.videoListALL=res.data.data
            let label=[]
                label[0]='全部'
            for (const key in res.data.labels) {
            label.push(res.data.labels[key])
            }
            this.videoList=[]
            for (const key in res.data.data) {
              res.data.data[key].showStatus=false
            }

            this.videoList=res.data.data
            for (const key in label) {
            this.selectLabelStatus[key]=false
            }
            
            this.selectLabelStatus[0]=true
            this.labels=label
            
              // console.log(this.videoList)
          }
        }
        
        
        this.getKeyWord()
    },
    setLabelVideo(e){
      console.log(e)
        for (const key in this.selectLabelStatus) {
          this.selectLabelStatus[key]=false
        }
  
        if(e==0){
          this.videoList=this.videoListALL
        }
        if(e!=0){
          let newVideoList=[]
          for (const key in this.videoListALL) {
            if(this.videoListALL[key].label==this.labels[e]){
              newVideoList.push(this.videoListALL[key])
            }
          }
          this.videoList=[]
          this.videoList=newVideoList
        }
        this.selectLabelStatus[e]=true
        this.$forceUpdate()
    },
    getKeyWord(){
      console.log(this.$route.query)
      console.log(this.labels)
      if(this.$route.query.keyword){
        for (const key in this.labels) {
            if (this.labels[key]==this.$route.query.keyword) {
                this.setLabelVideo(key)
            }
        }
      }
    }
  },
  mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      // console.log(window.screen.width)
      let clientWidths= document.documentElement.clientWidth
      // console.log(clientWidths)
      if(clientWidths<1300&&clientWidths>1000){
        clientWidths=1300
        that.isPc=true
        const scale = clientWidths / 1920; // 750
        document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px"; 
        }
        if(clientWidths<900){
          that.isPc=false
          const scale = clientWidths / 375; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        if(clientWidths>1300){
          that.isPc=true
          const scale = clientWidths / 1920; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
          // console.log('_________________')
            setRem();
        };
        setRem();

        if(this.$route.params.type){
          this.pageNum=this.$route.params.type
          console.log(this.pageNum)
        }
        this.jsonHttpTest()
  },

}
</script>
<style scoped lang="stylus">
.LogoName
  cursor pointer
  padding-left  100px
.main
    width 100%
    min-width 1300px
    position: fixed
    top 0
    z-index 20
    display flex
    justify-content space-between
    background rgba(255, 255, 255,1)
    box-sizing border-box
    box-shadow: 0px 2px 10px 0px rgba(0,96,204,0.1);
.name
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding 19px 0
.logo
    width: 24px;
    height: 24px;
    margin-right 11px
    padding 19px 0
    img
        width   100%
        height  100%

.rightBtn
    align-items center
    font-size   18px

.nameBtn
    position relative
    margin-right 40px
    width 100px
    text-align center
    cursor pointer
    padding 19px 0
    font-size: 16px;
    font-weight: 400;
    color: #333333;

.pcVideoList
  padding-top 60px
  width 1480px
  margin: 0 auto;
  padding-bottom  166px


.activer
  font-weight: 600

.activer::after
    content '' 
    display inline-block
    width 100px
    height 2px
    position absolute
    bottom -1px
    background  #000E2D
    border-radius 2px
    box-sizing  border-box
    left  0


.dec
  font-size: 20px;
  color: #333333;
  padding-top 6px

.showImgList
  display: flex;
  flex-wrap wrap
  text-align: center;
.showItemImgUrl
  width: 260px;
  height: 446px;
  img
    width 100%
    height  100%
.showTitle
  width: 270px;
  font-size: 20px;
  color: #333333;
  padding-top 15px
  text-align  left
.english
  font-size: 20px;
  color: #666666;

.showImgItem
  margin-top 80px
  cursor pointer
  margin-left 32px

.showImgItem:first-child
  margin-left 0

.showImgItem:nth-child(5n+1)
  margin-left 0

.showLeftPupop
  width 800px

.showVider
  width 750px
  height: 500px;
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
    height: 100%;

.LabelList
  display: flex;
  padding-top 40px
  width 100%
  flex-wrap:  wrap; 
  .labelItem
    width 140px
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    padding-top 30px
    cursor pointer;
.toptitle
  .showTitleText
    font-size: 24px;
    color: #333333;
    font-weight 600
  .showTitleTextDec
    font-size: 15px;
    color: #8A8A8A;
    padding-top 10px

.activeLabel
  width 140px
  border-bottom: 2px solid #121111;
  padding-bottom  10px
  font-size: 18px;
  font-weight: 600 !important;
  color: #121111;
  box-sizing  border-box;

.mproductList
    padding 40px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left

    img
        width   100%
        height  100%
.mshowTitleName
    font-size: 24px;
    color: #333333;
    font-weight 600
    padding-bottom  22px

.mshowTitleTwo
    font-size: 16px;
    color: #333333;
    padding-top 5px
    font-weight 600
.mshowImgList
    display flex
    text-align  left
    flex-wrap: wrap;

.mshowImgItem
    padding-top 20px
    width 31%
    margin-left 3%
    .mshowImgUrl
        margin: 0 auto;
.mshowImgItem:first-child
  margin-left 0

.mshowImgItem:nth-child(3n+1)
  margin-left 0

.mshowName
    font-size: 14px;
    color: #333333;
    font-weight 600
    padding-top 10px
  
.mshowLeftPupop
  max-width 400px
  min-width 350px
  margin: 0 auto;
  padding-top 34px

.mshowVider
  width 90%
  padding: 20px 0 40px
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
    height  100%



.mactiveLabel
  padding-bottom  10px
  font-size: 16px;
  font-weight: 600 !important;
  color: #121111;
  text-align: left

.mLabelList
    display: flex;
    width: 100%;
    overflow: auto;
    padding-top 10px
    box-sizing: border-box;
    text-align: left;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  .mlabelItem
    padding-right: 8px;
    font-size: 14px;
    min-width 80px
  .mlabelItem:first-child
    min-width 50px
.mLabelList::-webkit-scrollbar { width: 0!important;height:0; }
</style>

<style scoped lang="stylus">
.videoPupop
  .van-popup
    background  none
</style>