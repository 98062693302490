import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map'
import Vant from 'vant';
import 'vant/lib/index.css';

import { Notify } from 'vant';
import { Lazyload } from 'vant';
import axios from "axios"
//使用axios




Vue.prototype.axios = axios
Vue.use(Lazyload);
Vue.use(Vant)
Vue.use(Notify);
Vue.use(BaiduMap, {
  ak: 'tqAoSXdGsPKhe0PhkSu5GZEMwUpKDcVD'
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
