<template>
  <div>
    <div class="main">
      <div class="LogoName" @click="selectActiver(1)">
            <div class="logo"><img src="../../assets/logo.png" alt=""></div>
            <div class="name">
             重 交 智 安 ( 浙江 ) 科 技 发 展 有 限 公 司
            </div>
      </div>
      <div class="rightBtn">
          <div class="nameBtn" :class="pageNum==1?'activer':''" @click="selectActiver(1)">首页</div>
          <div class="nameBtn" :class="pageNum==2?'activer':''" @click="selectActiver(2)">产品介绍</div>
          <div class="nameBtn activer" style="min-width:6.6rem;"  @click="selectActiver(3)">新媒体介绍</div>
          <div class="nameBtn" :class="pageNum==4?'activer':''" @click="selectActiver(4)">渠道加盟</div>
          <div class="nameBtn" :class="pageNum==5?'activer':''" @click="selectActiver(5)">关于我们</div>
      </div>
  </div>
  <div class="content">
    <div class="toptitle">
        <div class="toptitleF">
          <div class="topTile">
            <div class="chang"></div>
            <div class="duan"></div>
          </div>
          <div class="showTitleText">新媒体案例</div>
          <div class="dec">拍摄 / 策划 /宣传 / 演练</div>
        </div>
    </div>

    <div class="showImgList">
        <div class="showImgItem" @click="setShowVideo(1)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/1.png" alt=""></div>
          <div class="showTitle">
            <div>都巴高速平安工程</div>

          </div>
        </div>

        <div class="showImgItem" @click="setShowVideo(2)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/2.png" alt=""></div>
          <div class="showTitle">
            <div>广西崇凭高速公路</div>

          </div>
        </div>

        <div class="showImgItem" @click="setShowVideo(3)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/3.png" alt=""></div>
          <div class="showTitle">
            <div>广西桂柳消防应急演练</div>

          </div>
        </div>

        <div class="showImgItem" @click="setShowVideo(4)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/4.png" alt=""></div>
          <div class="showTitle">
            <div>广西新柳南平安工程</div>

          </div>
        </div>


        <div class="showImgItem" @click="setShowVideo(5)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/5.png" alt=""></div>
          <div class="showTitle">
            <div>乐望应急演练</div>

          </div>
        </div>


        <div class="showImgItem" @click="setShowVideo(6)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/6.png" alt=""></div>
          <div class="showTitle">
            <div>临建三防应急演练</div>
          </div>
        </div>


        <div class="showImgItem" @click="setShowVideo(7)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/7.png" alt=""></div>
          <div class="showTitle">
            <div>平湖应急演练</div>

          </div>
        </div>


        <div class="showImgItem" @click="setShowVideo(8)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/8.png" alt=""></div>
          <div class="showTitle">
            <div>天平项目隧道</div>

          </div>
        </div>


        <div class="showImgItem" @click="setShowVideo(9)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/9.png" alt=""></div>
          <div class="showTitle">
            <div>仙居现场会宣传片</div>
          </div>
        </div>

        <div class="showImgItem" @click="setShowVideo(10)">
          <div class="showItemImgUrl"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/10.png" alt=""></div>
          <div class="showTitle">
            <div>诸暨坍塌应急演练</div>
          </div>
        </div>
        
        
    </div>
    <van-popup v-model="show" position="center" closeable >
       <div class="showLeftPupop">
          <div class="showVider" v-if="showItem==1">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%88%90%E5%93%81%C2%B7%E9%83%BD%E5%B7%B4%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF-%EF%BC%88%E4%B8%B2%E8%81%94%E7%9A%84%E5%89%AA%E8%BE%91%EF%BC%89.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
          
          <div class="showVider" v-if="showItem==2">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B4%87%E5%87%AD%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%EF%BC%88%E6%95%91%E6%8F%B4%E5%8F%8A%E7%82%B9%E8%AF%84%EF%BC%89.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==3">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B9%BF%E8%A5%BF%E6%A1%82%E6%9F%B3%E6%B6%88%E9%98%B2%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%EF%BC%88%E5%85%A8%E7%A8%8B%EF%BC%89_1.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==4">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%96%B0%E6%9F%B3%E5%8D%97%E5%B9%B3%E5%AE%89%E5%B7%A5%E7%A8%8B%E7%94%B3%E6%8A%A5-1024-3_1.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==5">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E4%B9%90%E4%B8%9A%E8%87%B3%E6%9C%9B%E8%B0%9F%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF%E9%9A%A7%E9%81%93%E5%9D%8D%E5%A1%8C%E5%BA%94%E6%80%A5%E6%95%91%E6%8F%B4%E6%BC%94%E7%BB%83.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==6">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/2021%E6%9D%AD%E5%B7%9E%E4%B8%B4%E5%BB%BA%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF%E5%BB%BA%E8%AE%BE%E9%A1%B9%E7%9B%AE%E7%9B%B4%E6%92%AD4_1.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==7">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%BC%94%E7%BB%83%E6%B4%BB%E5%8A%A8%E9%83%A8%E5%88%86_1.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==8">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B9%B3%E5%A4%A9%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%E5%89%AA%E8%BE%91%E6%88%90%E5%93%81%E8%A7%86%E9%A2%91.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==9">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E4%BB%99%E5%B1%85%E7%8E%B0%E5%9C%BA%E4%BC%9A.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==10">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/8.18%20%E8%AF%B8%E6%9A%A8235%E5%9B%BD%E9%81%93.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


       </div>
    </van-popup>
  </div>
  </div>
</template>


<script>
// // @ is an alias to /src
// import concent from '@/components/index.vue'
// import product from '@/components/product.vue'


export default {
  name: 'Home',

  components: {
    
  },
  data(){
      return{
        run:true,
        pageNum:3,
        showActiver:1,
        show:false,
        showItem:0
      }
  },
  methods:{
    selectActiver(num){
      let that=this
      that.showActiver=num
      this.$router.push({name:'Home',params:{type:num}})
    },
    setShowVideo(e){
      this.show=true
      this.showItem=e
    }
  }

}
</script>
<style scoped lang="stylus">
.LogoName
  cursor pointer
  padding-left  100px
.main
    width 100%
    min-width 1300px
    position: fixed
    top 0
    z-index 20
    display flex
    justify-content space-between
    background rgba(255, 255, 255,1)
    box-sizing border-box
    box-shadow: 0px 2px 10px 0px rgba(0,96,204,0.1);
.name
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding 19px 0
.logo
    width: 24px;
    height: 24px;
    margin-right 11px
    padding 19px 0
    img
        width   100%
        height  100%

.rightBtn
    align-items center
    font-size   18px

.nameBtn
    position relative
    margin-right 40px
    width 100px
    text-align center
    cursor pointer
    padding 19px 0
    font-size: 16px;
    font-weight: 400;
    color: #333333;

.content
  padding-top 100px
  width 1480px
  margin: 0 auto;
  padding-bottom  166px
.activer
  font-weight: 600

.activer::after
    content '' 
    display inline-block
    width 100px
    height 2px
    position absolute
    bottom -1px
    background  #000E2D
    border-radius 2px
    box-sizing  border-box
    left  0
.toptitle
  display flex
  justify-content: flex-start
  text-align: left;

.chang
    width: 78px;
    height: 12px;
    border: 2px solid #0173F3;
    box-sizing: border-box;

.duan
  width: 34px;
  height: 5px;
  background: #0173F3;
  margin-top  3px
.showTitleText
  font-size: 50px;
  color: #333333;
  font-weight 600
  padding-top 28px

.dec
  font-size: 20px;
  color: #333333;
  padding-top 6px

.showImgList
  display: flex;
  flex-wrap wrap
  text-align: center;
  justify-content: space-between;
.showItemImgUrl
  width: 460px;
  height: 460px;
  img
    width 100%
    height  100%
.showTitle
  font-size: 20px;
  color: #333333;
  font-weight: 600
  padding-top 15px
.english
  font-size: 20px;
  color: #666666;

.showImgItem
  padding-top 80px
  cursor pointer
.showLeftPupop
  width 800px

.showVider
  width 750px
  height: 500px;
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
</style>