<template>
  <div class="mobileMain">
    <div class="heardNav">
        <div class="leftIcon" @click="show=true"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>
    <div class="productList">
        <div class="showTitleName">渠道加盟</div>
        <div class="showTTitle">01/国家政策</div>
        <div class="showTDec">国家目前已经将安全生产纳入安全生产法和刑法，安全生产费也由原先的1.5%提到 2%，并实行全员安全生产责任制，足以看出国家对施工安全的重视性！</div>

        <div class="showTTitle">02/产品优势</div>
        <div class="showTDec">产品是由业内高知名度、高专业度、高影响力的专家团队倾力打造，提供安全教育、安全管理、安全咨询、三位一体的服务方案，赋能企业安全工作智慧升级！</div>

        <div class="showTTitle">03/产品亮点</div>
        <div class="showTDec">采用feed流的形式以及千人千面的算法根据岗位工种的不同，提供课程，课件内容为真实场景实拍针对性的采用先错后对的拍摄方式，
             有效提高工人的安全意识，避免安全隐患，用户可通过碎片化时间学习，大大提升了学习的便捷性。</div>

        <div class="showTTitle">04/加盟保证</div>
        <div class="showTDec">
            <div>1、我们为加盟商提供我们的开拓思路，拟定一致的发展战略体系。</div>
            <div>2、为每一个加盟商提供正式运营、营运发展培训，提供细致周到的咨询和服务。</div>
            <div>3、我们将定期组织产品培训与研讨，为共同的发展提供可靠的保证。</div>
        </div>
        
        <div class="showTitleName" >加盟须知</div>

        <div class="showTTitle">01</div>
        <div class="showTDec">授权经销商拥有报备项目的独家经营权，该客户独家服务对接锁定期为期2个月;如2个月内末能达成合作，其他授权经销商向公司报备可以进行对接合作。
            (如非经销商原因造成的项目延期，经销商可书面申请项目延期，审核通过后，可顺延项目报备时长。)</div>
        

        <div class="showTTitle">02</div>
        <div class="showTDec">享受公司提供的完整的人员培训，包括技术培训、产品培训、销售培训、标准化管理培训等。</div>
        


        <div class="showTTitle">03</div>
        <div class="showTDec">公司免费提供经营期间的营销策略及技术指导。</div>
        


        <div class="showTTitle">04</div>
        <div class="showTDec">公司提供会议支持，经销商举办招商会或招商沙龙:(经销商申请并提供方案,经公司核实后,视情况而定。</div>
        

        <div class="showTTitle">05</div>
        <div class="showTDec">业务支持，经销商举办产品说明会或重点客户汇报工作;(经销商申请并提供方案,经公司核实后,视情况而定。）</div>
        

        <div class="showTTitle">06</div>
        <div class="showTDec">广告支持，经销商根据市场情况决定区域性广告投放(经销商申请并提供方案,经公司核实后,视情况而定，广告投放期间，该区域未报备业务优先分配给该经销商。</div>

        <div class="showTTitle">07</div>
        <div class="showTDec">其他根据平台业务发展实际情况，经双方另外约定的事项。</div>
        
    </div>
    <van-popup v-model="show" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupop">
        <div class="showLeftPupopItem " @click="getUrl(0)">首页</div>
        <div class="showLeftPupopItem " @click="getUrl(1)">产品</div>
        <div class="showLeftPupopItem " @click="getUrl(2)">新媒体</div>
        <div class="showLeftPupopItem actives" >渠道加盟</div>
        <div class="showLeftPupopItem " @click="getUrl(4)">关于我们</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// var _czc = _czc || [];
// //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
// _czc.push(["_setAccount", "1280801298"]);
export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
        return{
          pupop:false,
          volume:0,
          show:false,
          showVideoStatus:false
        }
  },

  methods:{
    showVideo(){
      this.showVideoStatus=!this.showVideoStatus
    },
    getUrl(e){
      if(e==0){
        this.$router.push({name:'Home'})
      }
      if(e==1){
        this.$router.push({name:'m_product'})
      }
      if(e==2){
        this.$router.push({name:'m_video'})
      }
      if(e==3){
        this.$router.push({name:'m_join'})
      }
      if(e==4){
        this.$router.push({name:'m_aboutus'})
      }
    }
  },
   mounted() {
    var that = this;
    const baseSize = 16; // 32
    function setRem() {
    let clientWidths= document.documentElement.clientWidth
    if(clientWidths>900){
        that.$router.push({name:'Home',params:{type:4}})
      }
    }
  // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      setRem();
    };
    setRem();
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.heardNav
  display flex
  justify-content flex-start
  align-items center
  padding 15px 25px
  position absolute
  top 0
  left: 0
  background #fff
  width 100%
  box-sizing  border-box
  z-index 99
  .leftIcon
    padding-right 24px
    img
      width 20px
      height  20px
      vertical-align: middle
  .rightName
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 2px;

.productList
    padding 20px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left
    img
        width   100%
.showTitleName
    font-size: 24px;
    color: #333333;
    font-weight 600
    padding-top 60px

.showTTitle
    font-size: 16px;
    font-weight 600
    color: #333333;
    padding-top 20px
    padding-bottom  6px

.showTDec
    font-size: 14px;
    color: #666666;
    line-height 30px

</style>
