<template>
  <div class="main">
      <div class="LogoName" @click="selectActiver(1)">
            <div class="logo"><img src="../../assets/logo.png" alt=""></div>
            <div class="name">
             重 交 智 安 ( 浙江 ) 科 技 发 展 有 限 公 司
            </div>
      </div>
      <div class="rightBtn">
          <div class="nameBtn" :class="pageNum==1?'activer':''" @click="selectActiver(1)">首页</div>
          <div class="nameBtn" :class="pageNum==2?'activer':''" @click="selectActiver(2)">产品介绍</div>
          <div class="nameBtn" style="min-width:6.6rem;" :class="pageNum==3?'activer':''" @click="selectActiver(3)">新媒体介绍</div>
          <div class="nameBtn" :class="pageNum==4?'activer':''" @click="selectActiver(4)">渠道加盟</div>
          <div class="nameBtn" :class="pageNum==5?'activer':''" @click="selectActiver(5)">关于我们</div>
      </div>
  </div>
</template>


<script>
// // @ is an alias to /src
// import concent from '@/components/index.vue'
// import product from '@/components/product.vue'


export default {
  name: 'Home',
  props: {
    pageNum:{
      type:Number
    }
  },
  components: {
    
  },
  data(){
      return{
        run:true,
        showActiver:1
      }
  },
  methods:{
    selectActiver(num){
      let that=this
      that.showActiver=num
      this.$emit('msg',that.showActiver)
    }
  }

}
</script>
<style scoped lang="stylus">
.LogoName
  cursor pointer
  padding-left  100px
.main
    width 100%
    min-width 1300px
    position: fixed
    top 0
    z-index 20
    display flex
    justify-content space-between
    background rgba(255, 255, 255,1)
    box-sizing border-box
    box-shadow: 0px 2px 10px 0px rgba(0,96,204,0.1);
.name
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding 19px 0
.logo
    width: 24px;
    height: 24px;
    margin-right 11px
    padding 19px 0
    img
        width   100%
        height  100%

.rightBtn
    align-items center
    font-size   18px

.nameBtn
    position relative
    margin-right 40px
    width 100px
    text-align center
    cursor pointer
    padding 19px 0
    font-size: 16px;
    font-weight: 400;
    color: #333333;


.activer
  font-weight: 600

.activer::after
    content '' 
    display inline-block
    width 100px
    height 2px
    position absolute
    bottom -1px
    background  #000E2D
    border-radius 2px
    box-sizing  border-box
    left  0

</style>