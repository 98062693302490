<template>
  <div class="product_main">
    
    <div class="showtopText">
      <div class="showTopIcon">
        <div class="topList"></div>
        <div class="toplists"></div>
      </div>
      <div class="showTitle">
        <div class="leftName">
          <div class="showName">
            <div class="showNameTitle">新媒体服务</div>
            <div class="showDec">拍摄 / 策划 /宣传 / 演练</div>
          </div>
        </div>
        <div class="rightText">
          <div class="showTextOne">行业专家，工程技术，媒体服务三支团队，三支团队协同为项目提供一站式新媒体服务。行业专家对项目难点重点总结提升项目高度，
            策划现场会思路及方案。工程技术团队通过实地踏勘交流，从标准，创新，实用，规范角度提出现场整改应急演练意见及安全设计服务图纸。媒体团队以围绕项目亮点策划拍摄方案，
            多维度，创意化呈现项目亮点故事，为项目提质增效。</div>
        </div>
      </div>
    </div>
    <div class="showImgItem">
      <div class="imgItem" @click="setShowVideo(1)" >
        <div class="img" >
          <img v-if="showDecList[0]" src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/showImg.png" alt="">
          <img v-if="!showDecList[0]" src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video.png" alt="">

        </div>
        <div class="label" ><div class="text_center_mask">播放视频</div></div>
      </div>
      <div class="imgItem" @click="setShowVideo(2)" >
        <div class="img">
          <img v-if="!showDecList[1]" src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/showImg2.png" alt="">
          <img v-if="showDecList[1]" src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/videoColor.png" alt="">
        </div>
        <div class="label"><div class="text_center_mask">播放视频</div></div>
      </div>
    </div>

    <div class="bottomBtn">
      <div class="showBtnCenter" @click="showTwoStage"> 查看更多案例 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/showIcon.png" alt=""></div>
    </div>

    <van-popup v-model="show" position="center" closeable >
       <div class="showLeftPupop">
          <div class="showVider" v-if="showItem==1">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_3.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
          
          <div class="showVider" v-if="showItem==2">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_2.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


       </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'product',
  props: {
    msg: String
  },
  data(){
   return{
    showDecList:[false,false],
    show:false,
    showItem:0
    }
  },

   mounted() {
     
    },
   methods:{
    showTwoStage(){
      this.$router.push({name:'twoStage',params:""})
    },

    showPoster(e){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      that.showDecList[e]=true
      this.$forceUpdate()
    },
    handleHiedprice(){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      this.$forceUpdate()
    },

    setShowVideo(e){
      this.show=true
      this.showItem=e
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.product_main
  padding-top 80px
.showTopIcon
  width 1184px
  margin: 0 auto ;
  .topList
    width: 78px;
    height: 12px;
    border: 2px solid #0173F3;
  .toplists
    width: 34px;
    height: 5px;
    background: #0173F3;
    margin-top: 3px

.showTitle
  width 1184px
  display: flex;
  justify-content: space-between
  margin: 0 auto ;
  text-align: left
  padding-top 24px

.rightText
  width: 763px;
  font-size: 15px;
  color: #666666;
.showNameTitle
  font-size: 50px;
  color: #333333;
  font-weight 600
.showDec
  font-size: 20px;
  color: #333333;

.showTextOne
  font-size: 15px;
  color: #666666;
  line-height 26px

.showTextTwo
  padding-top 20px

.showImgItem
  width 1180px
  display: flex;
  justify-content: space-between;
  padding-top 65px
  margin: 0 auto;
.imgItems
  margin: 0 16px
.imgItem
  width: 576px;
  height: 400px;
  position relative
  overflow hidden
  cursor pointer
  img
    width 100%;
    height  100%

.label
  width 100%
  height 100%
  position absolute
  top 0
  display flex
  align-items center

.text_center_mask
  width: 256px;
  height: 64px;
  background: rgba(0,0,0,0.25);
  border-radius: 2px;
  backdrop-filter: blur(3.2px);
  margin: 0 auto;
  font-size: 26px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 64px;
  text-shadow: 0px 2px 0px rgba(0,0,0,0.6);

.showBtnCenter
  width: 300px;
  height: 50px;
  background: rgba(255,255,255,0.2);
  border-radius: 25px;
  border: 1px solid #BCBCBC;
  text-align: center;
  line-height 50px
  margin: 0 auto;
  cursor pointer
  font-size: 20px;
  font-weight: 400;
  color: #BCBCBC;
  img
    width 24px
    height  24px
    vertical-align: middle
    margin-left 10px
.bottomBtn
  padding-top:65px;

.showLeftPupop
  width 800px

.showVider
  width 750px
  height: 500px;
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
</style>
