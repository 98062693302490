<template>
  <div class="main">

      <div class="center_right">
       <!-- <video id="audioPlayer" poster="img/poster.7e25c47b.png"
        src="https://cdn.anzhique.com/mp4/video_1.mp4"
        autoplay="autoplay" 
        muted
        loop="loop"
        ref="audioPlayer"
          >
        <source src="movie.mp4" type="video/mp4">
        <source src="movie.ogg" type="video/ogg">
       </video> -->
       <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/pcIndexBg.png" alt="">
      </div>
      <!-- <div class="volumes" @click="showVolume"> <img v-if="volume==0" src="../../assets/icon/volume.png" alt=""> <img  v-if="volume!=0" src="../../assets/icon/volumes.png" alt=""></div> -->
      
      <div class="textFloatInVideo">
        <div class="textFloatInVideoCenten">
            <div class="showName">安知鹊</div>
            <div class="topTitle">打通安全生产最后一公里</div>
            <!-- <div class="showTwoBtn">
              <div class="BtnStyle">下载app</div>
              <div class="BtnStyle" style="margin-right:0;">使用小程序</div>
            </div> -->
        </div>
      </div>

  </div>
</template>

<script>
// var _czc = _czc || [];
// //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
// _czc.push(["_setAccount", "1280801298"]);
export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
        return{
          pupop:false,
          volume:0
        }
  },
  watch: {


  showVolume(val){
    console.log(val)
		if(val){
			this.$refs.audioPlayer.muted = 'muted'
		}
		else{
			this.$refs.audioPlayer.muted = ''
		}
	}
  },
  methods:{
    showQrCode(){
      this.$emit('pupopStatus',this.pupop)
      this.pupop=!this.pupop
    },
    showVolume(){
      //let video1=document.getElementById('video1')
      // this.$refs.localVideo.volume = 1;
      if(this.$refs.audioPlayer.muted){
        this.$refs.audioPlayer.muted=""
        this.volume=1
      }else{
        this.$refs.audioPlayer.muted="muted"
        this.volume=0
      }
      
      console.log(this.$refs.audioPlayer.muted)
      // console.log(this.$refs.audioPlayer)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.main
  width 100%
  min-width 1400px
  height 100%
  position relative

.center_right
  width 100%
  height  100%
  img
    width 100%
.center_right video
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;

.center_left
  width 36%
  min-width 700px
  position fixed
  left 0
  height  100%
  z-index 21
  padding-right 220px
  padding-left  70px
  box-sizing border-box

.showtext
  padding-top 200px
  font-size 50px
  color #333333
  text-align  left 
  padding-left  6px

.lastKilometer
  display flex
  align-items center
  text-align  left
  width 100%
  justify-content space-between
  padding-top 20px
  padding-bottom  20px

.last
  font-size 80px
  color #333333
  position relative
  span
    position relative
    z-index 1

.last::after
  display inline-block
  content ""
  width 152px
  height 30px
  background #019FFE
  opacity 0.65
  position absolute
  left 4px
  top 40px
  z-index 0

.kilometer
  font-size 80px
  color #019FFE

.lastKilometerImg
  width 30px
  height  110px
  margin 0 30px
  img
    width 100%
    height  100%

.whiteShadow
  position absolute
  top 0
  left 0
  width 100%
  height  100%
  z-index -1
  img
    width 100%
    height  100%

.label
  font-size 32px
  font-weight 500
  color #333333;
  line-height 45px
  text-align  left
  padding-left  6px

.textFloatInVideo
  position: absolute;
  top 80px
  width 100%
  padding-left  210px
  box-sizing: border-box;


.topTitle 
  width 100%
  text-align: left
  font-size: 30px;
  font-weight: 500;
  color: #2B3455;
  letter-spacing: 27px;

.showLabel
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  letter-spacing: 10px;
  padding-top:354px;

.showTwoBtn
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top 190px
  .BtnStyle
    width: 240px;
    height: 60px;
    background: rgba(255,255,255,0.2);
    border-radius: 40px;
    border: 1px solid #6EA5FF;
    font-size: 24px;
    font-weight: 400;
    color: #6EA5FF;
    line-height: 60px;
    text-align: center;
    margin-right  150px
    margin-top  70px
    cursor pointer

.showName
  font-size: 85px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2B3455;
  text-align: left 
  letter-spacing: 35px;
  padding 230px 0 60px 
</style>
