<template>
  <div class="product_main">
    <div class="showTopIcon">
        <div class="topList"></div>
        <div class="toplists"></div>
    </div>
    <div class="join">渠道加盟</div>
    <div class="textInfo">
        <div class="leftList">
            <div class="item">
                <div class="topTitle">一、国家政策</div>
                <div class="itemDec">国家目前已经将安全生产纳入安全生产法和刑法，安全生产费也由原先的1.5%提到 2%，并实行全员安全生产责任制，足以看出国家对施工安全的重视性！</div>
            </div>
            <div class="item">
                <div class="topTitle">二、产品优势</div>
                <div class="itemDec">产品是由业内高知名度、高专业度、高影响力的专家团队倾力打造，提供安全教育、安全管理、安全咨询、三位一体的服务方案，赋能企业安全工作智慧升级！</div>
            </div>
            <div class="item">
                <div class="topTitle">三、产品亮点</div>
                <div class="itemDec">采用feed流的形式以及千人千面的算法根据岗位工种的不同，提供课程，课件内容为真实场景实拍针对性的采用先错后对的拍摄方式， 有效提高工人的安全意识，避免安全隐患，用户可通过碎片化时间学习，大大提升了学习的便捷性。</div>
            </div>
            <div class="item">
                <div class="topTitle">四、加盟保证</div>
                <div class="itemDec">
                    <div>1、我们为加盟商提供我们的开拓思路，拟定一致的发展战略体系 </div>
                    <div>2、为每一个加盟商提供正式运营、营运发展培训，提供细致周到的咨询和服务</div>
                    <div>3、我们将定期组织产品培训与研讨，为共同的发展提供可靠的保证</div>
                </div>
            </div>
        </div>
        <div class="rightDiamond">
            <div class="title">加盟须知</div>
            <div  class="rightDiamondItem">1.授权经销商拥有报备项目的独家经营权，该客户独家服务对接锁定期为期2个月;
                如2个月内末能达成合作，其他授权经销商向公司报备可以进行对接合作。(如非经销商原因造成的项目延期，经销商可书面申请项目延期，审核通过后，可顺延项目报备时长。)</div>
            <div class="rightDiamondItem">2.享受公司提供的完整的人员培训，包括技术培训、产品培训、销售培训、标准化管理培训等。</div>
            <div class="rightDiamondItem">3.公司免费提供经营期间的营销策略及技术指导。</div>
            <div class="rightDiamondItem">5.业务支持，经销商举办产品说明会或重点客户汇报工作;(经销商申请并提供方案,经公司核实后,视情况而定。）</div>
            <div class="rightDiamondItem">4.公司提供会议支持，经销商举办招商会或招商沙龙:(经销商申请并提供方案,经公司核实后,视情况而定。</div>
            <div class="rightDiamondItem">6.广告支持，经销商根据市场情况决定区域性广告投放(经销商申请并提供方案,经公司核实后,视情况而定，广告投放期间，该区域未报备业务优先分配给该经销商。</div>
        </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'product',
  props: {
    msg: String
  },
  data(){
        return{

        }
  },

  mounted() {


    },
   methods:{
    

},
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.product_main
    padding-top 100px
    width   100%
    height  100%
    background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/woshou.png") no-repeat;
    background-size: 100% 90%
.showTopIcon,.textInfo
  width 1480px
  margin: 0 auto ;
  .topList
    width: 78px;
    height: 12px;
    border: 2px solid #0173F3;
  .toplists
    width: 34px;
    height: 5px;
    background: #0173F3;
    margin-top: 3px

.textInfo
    display: flex
    justify-content: space-between
.join
    width 1480px
    margin: 0 auto ;
    font-size: 50px;
    font-family: AlibabaPuHuiTiH;
    color: #333333;
    text-align: left;
    font-weight: 600
    padding-top 28px

.leftList
    text-align: left;

.topTitle
    width: 143px;
    height: 27px;
    font-size: 20px;
    color: #333333;
    font-weight 600
    padding-top: 50px
.itemDec
    font-size: 15px;
    width   800px
    padding-top 15px
    color: #666666;
    div
        padding-top 6px

.rightDiamond
    width: 522px;
    height: 629px;
    border-radius: 6px;
    border: 1px solid #333333;
    padding: 44px 24px
    box-sizing: border-box;
    position relative
    top -30px

.title
    font-size: 24px;
    font-weight 600
    color: #333333;

.rightDiamondItem
    padding-top 38px
    font-size: 15px;
    color: #666666;
    text-align: left
    
</style>
