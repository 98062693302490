<template>
  <div class="mobileMain">
    <div class="heardNav">
        <div class="leftIcon" @click="show=true"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>
    <div class="productList">
        <div class="showTitleName">关于我们</div>
        <div class="showAboutA"></div>
        <div class="brs"></div>
        <div class="showDec">重交智安（浙江）科技发展有限公司 是重庆交大交通安全科技研究院有限公司旗下专设，主营业务就是安全教育服务。</div>
        <div class="showDec">公司基于重庆交通大学的内外部的专家及师资力量，以及重庆交大安科院多年来丰富的市场实践经验，
             通过多年的实地调研和针对性考察，最终确定了安全教育的方向和具体实施方案， 2021年6月“安知鹊”平台应运而生，8月正式面向市场。</div>
        <div class="showImgs"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/rightImg.png" alt=""></div>

        <div class="showTitleName">合作案例</div>
        <div class="showAboutA"></div>
        <div class="showThreeList">
            <div class="showThreeItem">
                <div class="title">安全咨询&评价</div>
                <div class="buleNum">150+</div>
                <!-- <div class="showBtn">查看案例</div> -->
            </div>
            <div class="showThreeItem">
                <div class="title">数字化系统</div>
                <div class="buleNum">25+</div>
                <!-- <div class="showBtn">查看案例</div> -->
            </div>

            <div class="showThreeItem">
                <div class="title">新媒体服务</div>
                <div class="buleNum">150+</div>
                <!-- <div class="showBtn">查看案例</div> -->
            </div>
            
        </div>

        <div class="showTitleName">合作案例</div>
        <div class="showAboutA"></div>

        <div class="maps">
            <baidu-map class="map" :center="{lng:120.262759, lat:30.237565}" :zoom="18">
              <bm-circle :center="{lng:120.262459, lat:30.237365}" :radius="circlePath.radius" fill-color="#FB5B4E" :stroke-opacity="0.3" :stroke-weight="2" :editing="false"></bm-circle>
              <bm-marker :position="{lng:120.262459, lat:30.237265}" ></bm-marker>
            </baidu-map>
        </div>
        <div class="showDetailInfo">
            <div class="contact_dec_item" ><img style="vertical-align:middle;" src="../../assets/icon/address.png" alt=""> 浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601室</div>
            <div class="contact_dec_item"><img src="../../assets/icon/phone.png" alt=""> 199 0671 1623 / 199 0671 1626</div>
            <div class="contact_dec_item"><img src="../../assets/icon/email.png" alt=""> anzhiquecq@163.com</div>
            <!-- <div class="contact_dec_item"><img src="../../assets/icon/qq.png" alt=""> 258506508</div> -->
            <div class="contact_dec_item"><img src="../../assets/icon/WeChat.png" alt=""> 微信公众号：安知鹊安全课堂</div>
        </div>
    </div>
    <van-popup v-model="show" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupop">
        <div class="showLeftPupopItem " @click="getUrl(0)">首页</div>
        <div class="showLeftPupopItem " @click="getUrl(1)">产品</div>
        <div class="showLeftPupopItem " >新媒体</div>
        <div class="showLeftPupopItem " @click="getUrl(3)">渠道加盟</div>
        <div class="showLeftPupopItem actives" >关于我们</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'  //Circle
import BmCircle from 'vue-baidu-map/components/overlays/Circle.vue'  //Circle

export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {
    BaiduMap,
    BmMarker,
    BmCircle
  },
  data(){
        return{
          pupop:false,
          volume:0,
          show:false,
          showVideoStatus:false,
          circlePath: {
                // center: {
                //   lng: 120.256059,
                //   lat: 30.231271
                // },
                
                radius: 50
            },
        }
  },

  methods:{
    showVideo(){
      this.showVideoStatus=!this.showVideoStatus
    },
    getUrl(e){
      if(e==0){
        this.$router.push({name:'Home'})
      }
      if(e==1){
        this.$router.push({name:'m_product'})
      }
      if(e==2){
        this.$router.push({name:'m_video'})
      }
      if(e==3){
        this.$router.push({name:'m_join'})
      }
      if(e==4){
        this.$router.push({name:'m_aboutus'})
      }
    }
  },
  mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      let clientWidths= document.documentElement.clientWidth
      if(clientWidths>900){
         that.$router.push({name:'Home',params:{type:5}})
        }
    
      }
        // <!--把window.onresize事件挂在到mounted函数上-->
     window.onresize = () => {
        setRem();
      };
    setRem();
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.heardNav
  display flex
  justify-content flex-start
  align-items center
  padding 15px 25px
  position absolute
  top 0
  left: 0
  background #fff
  width 100%
  box-sizing  border-box
  z-index 99
  .leftIcon
    padding-right 24px
    img
      width 20px
      height  20px
      vertical-align: middle
  .rightName
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 2px;

.productList
    padding 20px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left
    img
        width   100%
.showTitleName
    font-size: 24px;
    color: #333333;
    font-weight 600
    padding-top 60px

.showTTitle
    font-size: 16px;
    font-weight 600
    color: #333333;
    padding-top 20px
    padding-bottom  6px

.showTDec
    font-size: 14px;
    color: #666666;
    line-height 30px
.showAboutA
    font-size: 16px;
    color: #333333;
    padding-top 5px

.showThreeList
    display: flex;
    justify-content: space-between;
    text-align: center;
    .title
        padding-top 45px
        font-size: 14px;
        color: #333333;
        font-weight 600
    .buleNum
        font-size: 30px;
        font-weight: bold;
        color: #0173F3;
        padding 15px 0
    .showBtn

        font-size: 12px;
        text-decoration: underline
        color: #666666;
.brs
    padding-top 20px
.showDec
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    padding-bottom  10px

.maps
    width   100%
    height  200px
    margin-top  20px
    .map
        width   100%
        height  100%

.contact_dec_item
    display flex
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    align-items: center;
    margin-top  15px
    img
        width   16px
        height  16px
        margin-right 16px

.showDetailInfo  
    padding-top 35px
</style>
