<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<!--浩克问卷调查接入-->
<!-- Start of Howxm client code snippet -->
<script>
function _howxm(){_howxmQueue.push(arguments)}
window._howxmQueue=window._howxmQueue||[];
if(process.env.NODE_ENV==='production'){
  console.log("当前正式",process.env.NODE_ENV)
   _howxm('setAppID','8202b0c9-95e1-40e9-b9af-56d16f098527');
}else{
  console.log('当前测试',process.env.NODE_ENV)
  _howxm('setAppID','16a3fd9d-0db3-46a9-bdab-01a3cb9d95b5');
}
(function(){var scriptId='howxm_script';
  if(!document.getElementById(scriptId)){
    var e=document.createElement('script'),
        t=document.getElementsByTagName('script')[0];
    e.setAttribute('id',scriptId);
    e.type='text/javascript';e.async=!0;
    e.src='https://static.howxm.com/sdk.js';
    t.parentNode.insertBefore(e,t)}})();
</script>
<!-- End of Howxm client code snippet -->
<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  height 100%
  width   100%

*
  margin 0
  padding 0

html ,body
  width 100%
  height 100%

.LogoName,.rightBtn,.video_top,.videolist
  display flex
  
.BMap_cpyCtrl {
   display: none;
 }
.anchorBL {
   display: none;
}
</style>
