<template>
    <div class="showAllConent" id="scroll-box">
        <div class="main">
            <div class="LogoName" @click="selectActiver(1)">
                    <div class="logo"><img src="../../assets/logo.png" alt=""></div>
                    <div class="name">
                    重 交 智 安 ( 浙江 ) 科 技 发 展 有 限 公 司
                    </div>
            </div>
            <div class="rightBtn">
                <div class="nameBtn" :class="pageNum==1?'activer':''"  @click="selectActiver(1)">首页</div>
                <div class="nameBtn" :class="pageNum==2?'activer':''"  @click="selectActiver('productList')">产品介绍</div>
                <div class="nameBtn" style="min-width:6.6rem;" :class="pageNum==3?'activer':''" @click="selectActiver('videoList')">新媒体介绍</div>
                <div class="nameBtn" :class="pageNum==4?'activer':''"  @click="selectActiver('joinUs')">渠道加盟</div>
                <div class="nameBtn" :class="pageNum==5?'activer':''"  @click="selectActiver('aboutUs')">关于我们</div>
            </div>
        </div>
        <div>
            <div class="IndexMain">
                <div class="center_right">
                <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/pcIndexBg.png" alt="">
                </div>
                <div class="textFloatInVideo">
                    <div class="textFloatInVideoCenten">
                        <div class="showName">安知鹊</div>
                        <div class="textDecss">打通安全生产最后一公里</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="showAll" id="productList">
            <div class="showAll_first">
                <div class="showTopName">
                    <div class="titleName">产品介绍</div>
                    <div class="titleEnglist">—  introduce  —</div>
                </div>
                <div class="topList">
                    <div class="showListItem widthNumber">
                        <div class="productList" @click="setShowItem(index)" :class="arrStatus==index? 'showTopListItem':''" v-for="(item,index) in productList" :key="index">
                            <div class="productItem">
                                <img v-if="arrStatus==index" :src="item.topBtnImgs" alt="">
                                <img v-if="arrStatus!=index" :src="item.topBtnImg" alt="">
                            </div>
                            <div class="productName">{{item.titleDec}}</div>
                            <div class="productLine"></div>
                        </div>
                    </div>
                    <div class="productDecList widthNumber">
                        <div class="productLeft">
                            <div class="productLeftName">{{showSelect.titleDec}}</div>
                            <div class="productLeftEnglish">introduce</div>
                            <div class="productLeftline"></div>
                            <div class="productLeftDec">{{showSelect.titleDeatil}}</div>
                        </div>
                        <div class="productRight"><img :src="showSelect.url" alt=""></div>
                    </div>
                </div>
            </div>

            <div class="showAll_second" id="videoList">
                <div class="showTopName">
                    <div class="titleName">新媒体服务</div>
                    <div class="titleEnglist">拍摄 / 策划 /宣传 / 演练</div>
                </div>
                <div class="videoDec">行业专家，工程技术，媒体服务三支团队，三支团队协同为项目提供一站式新媒体服务。行业专家对项目难点重点总结提升项目高度，
                    策划现场会思路及方案。工程技术团队通过实地踏勘交流，从标准，创新，
                    实用，规范角度提出现场整改应急演练意见及安全设计服务图纸。
                    媒体团队以围绕项目亮点策划拍摄方案，多维度，创意化呈现项目亮点故事，为项目提质增效。</div>
                <div class="showImgItem">
                    <div class="imgItem" >
                        <div class="img" >
                        <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/indexVideoImg1.png" alt="">

                        </div>
                        <div class="label">
                            <div class="text_center_mask" @click="setShowVideo(1)" >
                                <div class="playIcon"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/playIcon.png" alt=""></div>
                                <div class="playBtn">案例视频</div>
                            </div>
                        </div>
                    </div>
                    <div class="imgItem"  >
                        <div class="img">
                        <img  src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/indexVideoImg.png" alt="">
                        </div>
                        <div class="label">
                            <div class="text_center_mask" @click="setShowVideo(2)">
                                <div class="playIcon"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/playIcon.png" alt=""></div>
                                <div class="playBtn">案例视频</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottomBtn">
                    <div class="showBtnCenter" @click="showTwoStage"> 查看更多视频 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/showIcon.png" alt=""></div>
                </div>
            </div>
            <!-- 第三栏 -->
            <div class="thirdItem" id='joinUs'>
                <div class="showTopName">
                    <div class="titleName">渠道加盟</div>
                    <div class="titleEnglist">—    join us    —</div>
                </div>
                <div class="fourLits">
                        <div class="swiper_box">
                            <div class="swiper-container ">
                            <div class="swiper-wrapper" >
                                <div class="swiper-slide">
                                    <div class="showSwiperOne">
                                        <div class="fourLIst">
                                            <div class="fourItem">
                                                <div class="leftNumber">一</div>
                                                <div  class="rightIcon"></div>
                                                <div class="rightText">
                                                    <div class="rightTextTitle">国家政策</div>
                                                    <div class="rightTextDec">国家目前已经将安全生产纳入安全生产法和刑法，安全生产费也由原先的1.5%提到 2%，
                                                        并实行全员安全生产责任制，足以看出国家对施工安全的重视性！</div>
                                                </div>
                                            </div>


                                            <div class="fourItem">
                                                <div class="leftNumber">二</div>
                                                <div  class="rightIcon"></div>
                                                <div class="rightText">
                                                    <div class="rightTextTitle">产品优势</div>
                                                    <div class="rightTextDec">产品是由业内高知名度、高专业度、高影响力的专家团队倾力打造，
                                                        提供安全教育、安全管理、安全咨询、三位一体的服务方案，赋能企业安全工作智慧升级！
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="fourItem">
                                                <div class="leftNumber">三</div>
                                                <div  class="rightIcon"></div>
                                                <div class="rightText">
                                                    <div class="rightTextTitle">产品亮点</div>
                                                    <div class="rightTextDec">采用千人千面的算法，根据岗位工种的不同，提供课程，课件内容为真实场景实拍，
                                                    针对性的采用先错后对的拍摄方式， 有效提高工人的安全意识，用户可通过碎片化时间学习，大大提升了学习的便捷性。</div>
                                                </div>
                                            </div>


                                            <div class="fourItem">
                                                <div class="leftNumber">四</div>
                                                <div  class="rightIcon"></div>
                                                <div class="rightText">
                                                    <div class="rightTextTitle">加盟保证</div>
                                                    <div class="rightTextDec">
                                                        <div>1、我们为加盟商提供我们的开拓思路，拟定一致的发展战略体系</div>
                                                        <div>2、我们为加盟商提供运营相关培训，及细致周到的咨询服务</div>
                                                        <div>3、我们定期组织产品培训与研讨，为共同的发展提供可靠的保证</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="showSwiperOne showSwiperOnePosition">
                                        <div class="showSwiperOnes">
                                            <div class="topTitle">
                                                加盟须知
                                            </div>
                                            <div class="sanjiao"></div>
                                            <div class="joinUsText">
                                                <div>1.授权经销商拥有报备项目的独家经营权，该客户独家服务对接锁定期为期2个月;如2个月内末能达成合作，
                                                    其他授权经销商向公司报备可以进行对接合作；</div>
                                                <div>2.享受公司提供的完整的人员培训，包括技术培训、产品培训、销售培训、标准化管理培训等；</div>
                                                <div>3.公司免费提供经营期间的营销策略及技术指导；</div>
                                                <div>4.公司提供会议支持，经销商举办招商会或招商沙龙； </div>
                                                <div>5.业务支持，经销商举办产品说明会或重点客户汇报工作;</div>
                                                <div>6.广告支持，经销商根据市场情况决定区域性广告投放。</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                </div>
            </div>
            <!-- 第三栏底 -->
            <!-- 第四栏 -->
            <div class="fourthItem" id="aboutUs">
                <div class="showTopName">
                    <div class="titleName">关于我们</div>
                    <div class="titleEnglist">—    About us    —</div>
                </div>

                    <div class="topTextInfo">
                        <div class="aboutInfoLeft">
                            <div class="topIconTitle">
                                <div class="introduce">
                                    <div class="introduceDec">公司介绍</div>
                                    <div class="introduce">introduce</div>
                                    <div class="introduceLine"></div>
                                </div>

                            <div class="topDec firstTopDec">重交智安（浙江）科技发展有限公司是重庆交大交通安全科技研究院有限公司旗下专设，主营业务就是安全教育服务。</div>
                            <div class="topDec">公司基于重庆交通大学的内外部的专家及师资力量，以及重庆交大安科院多年来丰富的市场实践经验， 通过多年的实地调研和针对性考察，
                                最终确定了安全教育的方向和具体实施方案， 2021年6月“安知鹊”平台应运而生，8月正式面向市场。</div>
                            </div>

                        </div>
                        <div class="aboutInfoRight"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/rightImg.png" alt=""></div>
                        </div>

                        <div class="caseListNumber showCaseListNumberBg">
                            <div class="topIconTitle">
                                <div class="showTopName">
                                    <div class="titleName">合作案例</div>
                                    <div class="titleEnglist">—    case    —</div>
                                </div>
                            </div>

                            <div class="showTextNums">
                            <div class="showThreeList">
                                <div class="itemBox" style="height: 400px;" @mouseover="showPoster(0)"  @mouseleave="handleHiedprice">
                                <div class="showItemBule" style="height: 400px;" v-if="showDecList[0]">
                                    <div class="buleTitle">安全咨询&评价</div>
                                    <div class="buleDec">
                                    <div>广西交投安全生产检查考核</div>
                                    <div>国道310线安全管理咨询</div>
                                    <div>G213 线安全顾问服务</div>
                                    <div>临金高速公路用电方案咨询</div>
                                    <div>沪蓉高速公路速度论证咨询</div>
                                    <div>G15沈海高速施工图安全评价</div>
                                    <div>叙古高速公路运营安全评价</div>
                                    <div>成巴高速安全评价</div>
                                    </div>
                                    <!-- <div class="moreBtn">更多案例</div> -->
                                </div>
                                <div class="showItem" style="border:none;"  v-if="!showDecList[0]">
                                    <div class="showTextTitle">安全咨询&评价</div>
                                    <div class="showTextNum">150+</div>
                                </div>
                                </div>


                                <div class="itemBox" style="height: 400px;" @mouseover="showPoster(1)"  @mouseleave="handleHiedprice">
                                <div class="showItemBule" style="height: 400px;" v-if="showDecList[1]">
                                    <div class="buleTitle">数字化系统</div>
                                    <div class="buleDec">
                                        <div>临晋高速TJ03段安全教育平台</div>
                                        <div>天峨巴马高速公路教育平台</div>
                                        <div>大新凭祥高速公路安全管理平台</div>
                                        <div>宁波六横公路大桥安全管理平台</div>
                                        <div>杭甬复线宁波一期安全管理平台</div>
                                        <div>岳圩口岸公路数智管理平台</div>
                                        <div>宁波昆亭塘溪段安全管理平台</div>
                                        <div>国道235TJ01段安全管理平台</div>
                                    </div>
                                    <!-- <div class="moreBtn">更多案例</div> -->
                                </div>
                                <div class="showItem" v-if="!showDecList[1]">
                                    <div class="showTextTitle">数字化系统</div>
                                    <div class="showTextNum">25+</div>
                                </div>
                                </div>
                                <div class="itemBox" style="height: 400px;" @mouseover="showPoster(2)"  @mouseleave="handleHiedprice">
                                <div class="showItemBule" style="height: 400px;" v-if="showDecList[2]">
                                    <div class="buleTitle">新媒体服务</div>
                                    <div class="buleDec">
                                        <div>巴马凭祥应急演练</div>
                                        <div>诸暨235国道坍塌应急演练</div>
                                        <div>广西田巴高速品质工程宣传片</div>
                                        <div>杭绍甬项目安全警示片</div>
                                        <div>隧道工厂工点化技术交底视频</div>
                                        <div>九套台隧道施工工艺纪录片</div>
                                        <div>台州朱溪至白塔工程现场会</div>
                                        <div>桂金高速现场会</div>
                                    </div>
                                    <!-- <div class="moreBtn">更多案例</div> -->
                                </div>
                                <div class="showItem" style="border:none;" v-if="!showDecList[2]"> 
                                    <div class="showTextTitle">新媒体服务</div>
                                    <div class="showTextNum">30+</div>
                                </div>
                                </div>
                            
                            </div>
                            </div>

                        </div>


                        <div class="caseListNumber showCaseListNumber">
                            <div class="topIconTitle">
                            <div class="showTopName">
                                <div class="titleName">联系我们</div>
                                <div class="titleEnglist">—    contact    —</div>
                            </div>
                            </div>

                            <div class="showTellInfo">
                            
                            <div class="map">
                                <baidu-map class="map" :center="{lng:120.262759, lat:30.237565}" :zoom="18">
                                <!-- <bm-circle :center="{lng:120.262459, lat:30.237365}" :radius="circlePath.radius" fill-color="#FB5B4E" :stroke-opacity="0.3" :stroke-weight="2" :editing="false"></bm-circle> -->
                                <bm-marker :position="{lng:120.262459, lat:30.237265}" ></bm-marker>
                                </baidu-map>
                            </div>

                                <div class="showDetailInfo">
                                <div class="contact_dec_item" ><img src="../../assets/icon/address.png" alt=""> 浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601室</div>
                                <div class="contact_dec_item"><img src="../../assets/icon/phone.png" alt=""> 199 0671 1623 / 199 0671 1626</div>
                                <div class="contact_dec_item"><img src="../../assets/icon/email.png" alt=""> anzhiquecq@163.com</div>
                                <!-- <div class="contact_dec_item"><img src="../../assets/icon/qq.png" alt=""> 258506508</div> -->
                                
                                <div class="contact_dec_item"><img src="../../assets/icon/WeChat.png" alt=""> 微信公众号：重安视界</div>
                                <div class="showQrcode"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/qrcode.png" alt=""></div>
                                </div>
                            </div>


                        </div>


                    <div class="footer">
                        <div> 2021@重交智安  | 
                            <a href="https://beian.miit.gov.cn/?token=9c9d2aa2-7eed-4bdd-87c5-d4e33ea46caf#/Integrated/index" target=”_blank”> 浙ICP备2021035454号 </a> 
                            |  重交智安（浙江）科技发展有限公司
                        </div>        
                        <div>中国互联网举报中心  |  计算机软件著作权证书号：8180649号  |  客户服务热线：0571-86885135  |  违法和不良信息举报：0571-86885135  |  举报邮箱：753221776@qq.com</div>
                        <div> 地址：浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601、1602、1603、1604室 营业执照 </div>
                    </div>
            </div>
            <!-- 第四栏 底 -->
        </div>

        <van-popup v-model="showVideoPlay" @close="closes" position="center" closeable >
            <div class="showLeftPupop">
                <div class="showVider" v-if="showItem==1">
                    <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                        src="https://cdn.anzhique.com/mp4/video_3.mp4"
                        autoplay="autoplay" 
                        controls
                        loop="loop"
                        ref="audioPlayer"
                        >
                        <source src="movie.mp4" type="video/mp4">
                        <source src="movie.ogg" type="video/ogg">
                    </video>
                </div>
                
                <div class="showVider" v-if="showItem==2">
                    <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                        src="https://cdn.anzhique.com/mp4/video_2.mp4"
                        autoplay="autoplay" 
                        controls
                        loop="loop"
                        ref="audioPlayer"
                        >
                        <source src="movie.mp4" type="video/mp4">
                        <source src="movie.ogg" type="video/ogg">
                    </video>
                </div>


            </div>
        </van-popup>

    </div>
</template>

<script>
import Swiper, { Pagination, Navigation, Autoplay, EffectCards } from 'swiper' // js 模块
import 'swiper/swiper-bundle' // css 模块swiper/css/effect-cards
import 'swiper/swiper-bundle.min.css'
Swiper.use([Pagination, Navigation, Autoplay,EffectCards])
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'  //Circle
import BmCircle from 'vue-baidu-map/components/overlays/Circle.vue'  //Circle

export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {
    BaiduMap,
    BmMarker,
    BmCircle
  },
  data(){
      return{
        run:true,
        showActiver:1,
        pageNum:1,
        setectPrice:1,
        arrStatus:0,
        showSelect:{},
        showItem:0,
        showVideoPlay:false,
        productList:[{
                topBtnImg:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/product.png',
                topBtnImgs:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/productw.png',
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/productleft1.png',
                titleDec:"安全数智管理平台",
                titleDeatil:'提供企业级项目施工全过程安全数智化监管服务，包含工程信息数智化，施工计划管理，标准安全防护措施，风险动态管控及预警，现场人员/机械设备及车辆管控等，全方位提升项目工程安全信息化。'
            },{
                topBtnImg:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/product1.png',
                topBtnImgs:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/product1w.png',
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/productleft2.png',
                titleDec:"双重预防管理平台",
                titleDeatil:'结合施工计划主动识别风险并及时预警，风险处理落实到人，闭环流程处理机制。形成电子档案及汇报材料，实现管理留痕可追溯。系统内置8000余条隐患处理措施，保障处理人员快速妥善进行问题处理。实现安全隐患治理“信息化” “可视化” “数字化” “清单化” “闭环化” 。'
            },{
                topBtnImg:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/product2.png',
                topBtnImgs:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/product2w.png',
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/productleft3.png',
                titleDec:"人员管理及教育平台",
                titleDeatil:'人员管理结合安全培训，以信息数字化手段实现对施工人员的日常工作管控，将安全生产落实到每一名一线作业人员。系统内置庞大的视频课件及试卷库，包含三级教育，班前会，日常安全培训，覆盖各类工种。所有人员信息，学习及考试台账均可下载，线下归档留存。'
        }],
        circlePath: {
        // center: {
        //   lng: 120.256059,
        //   lat: 30.231271
        // },
        
            radius: 50
        },
        showDecList:[true,false,false],
      }
  },

  mounted() {
      let that=this
    //   this.showSelect=this.showAll[0]
      this.showSelect=this.productList[0]
      const swiper = new Swiper('.swiper-container', {
      // direction: 'vertical',

      loop: true,
      effect: "Pagination",
      speed: 500,
      spaceBetween: 0,
      centeredSlides: false,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        hideOnClick:false,
        type:'bullets',
        clickable: true // 允许分页点击跳转
      },
    //   cardsEffect: {
    //     slideShadows: false,
    //     transformEl:'.text',
    //   },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
       on: {
       slideChangeTransitionStart: function(activeIndex){
          that.setectPrice=this.activeIndex+1
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        hideOnClick: true,
        hiddenClass: 'my-button-hidden',
      }
    })
    window.onresize = () => {
      if(document.documentElement.clientWidth>1300){
        const swiper = new Swiper('.swiper-container', {
            // direction: 'vertical',

            loop: true,
            effect: "Pagination",
            speed: 500,
            spaceBetween: 0,
            centeredSlides: false,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                hideOnClick:false,
                type:'bullets',
                clickable: true // 允许分页点击跳转
            },
            //   cardsEffect: {
            //     slideShadows: false,
            //     transformEl:'.text',
            //   },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
            on: {
                slideChangeTransitionStart: function(activeIndex){
                that.setectPrice=this.activeIndex+1
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                hideOnClick: true,
                hiddenClass: 'my-button-hidden',
            }
            })
        }
          
      };
      this.swiper=swiper
      window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
      window.removeEventListener('scroll', this.handleScroll)
    },
  methods:{
    setShowItem(e){
       
       this.arrStatus=e
       this.showSelect=this.productList[e]
       this.$forceUpdate()
    },
    setShowVideo(e){
      this.showVideoPlay=true
      this.showItem=e
    },
    closes(){
      console.log(222222)
      console.log(this.$refs.audioPlayer)
      this.$refs.audioPlayer.pause()
    },
    showTwoStage(){
        let routeData=this.$router.resolve({name:'videoList1',params:""})
        window.open(routeData.href, '_blank')
    },
    showPoster(e){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      that.showDecList[e]=true
      this.$forceUpdate()
    },


    handleHiedprice(){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      this.$forceUpdate()
    },

    selectActiver(id){
      let that=this
        if(id==1){
            const box = document.getElementById('scroll-box')

            // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
   
            box.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }else{
            const box = document.getElementById('scroll-box')
            let scrollItem = document.getElementById(id)
            // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
            this.offsetTop = scrollItem.offsetTop
            box.scrollTo({
                top: parseFloat(scrollItem.offsetTop)-50,
                behavior: "smooth",
            });
        }
    },
     handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = document.querySelector('#scroll-box').scrollTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
    //   console.log(scrollTop,offsetTop)
      let scrollItem1 = document.getElementById('productList').offsetTop
      let scrollItem2 = document.getElementById('videoList').offsetTop
      let scrollItem3 = document.getElementById('joinUs').offsetTop
      let scrollItem4 = document.getElementById('aboutUs').offsetTop
      if(0<offsetTop){
        this.pageNum=1
      }
      if(scrollItem1<=offsetTop+400){
        this.pageNum=2
      }
      if(scrollItem2<=offsetTop+300){
        this.pageNum=3
      }
      if(scrollItem3<=offsetTop+400){
        this.pageNum=4
      }
      if(scrollItem4<=offsetTop+400){
        this.pageNum=5
      }
    },
  }

}
</script>
<style scoped lang="stylus">
.LogoName
  cursor pointer
  padding-left  100px
.main
    width 100%
    min-width 1300px
    position: fixed
    top 0
    z-index 20
    display flex
    justify-content space-between
    background rgba(255, 255, 255,1)
    box-sizing border-box
    box-shadow: 0px 2px 10px 0px rgba(0,96,204,0.1);
.name
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding 19px 0
.logo
    width: 24px;
    height: 24px;
    margin-right 11px
    padding 19px 0
    img
        width   100%
        height  100%

.rightBtn
    align-items center
    font-size   18px

.nameBtn
    position relative
    margin-right 40px
    width 100px
    text-align center
    cursor pointer
    padding 19px 0
    font-size: 16px;
    font-weight: 400;
    color: #333333;


.activer
  font-weight: 600

.activer::after
    content '' 
    display inline-block
    width 100px
    height 2px
    position absolute
    bottom -1px
    background  #000E2D
    border-radius 2px
    box-sizing  border-box
    left  0
.showAll
    padding-top 100px
.widthNumber
    width   1440px
    margin: 0 auto;
.showListItem 
    display: flex;
    justify-content: space-between;
.showTopListItem
    width: 350px;
    height: 200px;
    background: #0173F3;
    box-shadow: 0px 10px 20px 0px rgba(0,83,176,0.3);
    padding 28px 56px
    box-sizing: border-box;
.productList
    width: 350px;
    height: 200px;
    padding 28px 56px
    box-sizing: border-box;
    cursor pointer
.productName
    font-size: 20px;
    color: #666666;
    padding 22px 0 16px
.productLine
    width: 240px;
    height: 1px;
    background:  #E5E5E5;
    position relative
.productLine::after
    content: '.';
    display: flex;
    width: 15px;
    height: 1px;
    background:  #0173F3;
    position: absolute
    color: #0173F3;
    left: 0;
    top 0
.topList
    padding-top 74px
.productItem
        width: 80px;
        height: 80px;
        margin  0 auto
    img
        width   100%
        height  100%
.showTopListItem
    .productName
        
        text-align: center;
        font-size: 20px;
        color: #FFFFFF;
    .productLine
        width: 240px;
        height: 1px;
        background:  #E5E5E5;
.productDecList
    display: flex;
    justify-content: space-between;
    padding-top 128px
.productLeft
    text-align: left;
.productLeftDec
    width   630px
    font-size: 16px;
    color: #666666;
    line-height: 38px;
    padding-top 46px
.productLeftName
    font-size: 30px;
    color: #333333;
    padding-top 40px
.productLeftEnglish
    font-size: 15px;
    color: #AAAAAA;
    padding-bottom  15px
.productLeftline
    width: 25px;
    height: 4px;
    background: #0173F3;
.productRight
    width: 500px;
    height: 302px;
    img
        width   100%
        height  100%
.showAllConent
    height: 100%;
    overflow: auto;
.titleName
    font-size: 24px;
    color: #333333;
    text-align: center;
    padding-top:   20px
.titleEnglist
    font-size: 15px;
    color: #8A8A8A;
    padding-top 10px
    padding-bottom 20px

.videoDec
    width: 1060px;
    font-size: 16px;
    color: #666666;
    margin: 0 auto;
    line-height 30px
    text-align: center;

.showAll_second
    padding-top 120px

.showImgItem
    width   1440px
    display: flex;
    margin: 0 auto;
    padding-top 50px
    padding-bottom: 100px;
.imgItem
    width   720px
    height  500px
    overflow hidden
    position relative
    img
        width   100%

.label
    width   100%
    height: 100%;
    position: absolute;
    left: 0
    top 0
    display: flex;
    align-items: center;
    justify-content: center;
.playBtn
    font-size: 34px;
    color: #FFFFFF;

.text_center_mask
    width   170px
    cursor pointer
.playIcon
    width   40px
    height  40px
    margin: 0 auto
    padding-bottom  10px
    img 
        width   100%
        
.showLeftPupop
  width 800px

.showVider
  width 750px
  height: 500px;
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
.bottomBtn
    display flex
    justify-content: center;
    .showBtnCenter
        width: 300px;
        height: 50px;
        background: rgba(255,255,255,0.2);
        border-radius: 25px;
        border: 1px solid #BCBCBC;
        text-align: center;
        line-height 50px
        margin: 0 auto;
        cursor pointer
        font-size: 20px;
        font-weight: 400;
        color: #BCBCBC;
        img
            width 24px
            height  24px
            vertical-align: middle
            margin-left 10px

.fourLits
    width   100%
    overflow hidden
    height  500px
    position    relative
    .showSwiperOne
        width   100%
        height: 500px;
        background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/woshou.png") no-repeat;
        background-size: 100% ;
        background-position-y   -100px
        background-color: rgba(1, 115, 243, .5)
    .swiper_box{
        --swiper-pagination-color: #fff;/* 两种都可以 */
    }

.thirdItem
    padding-bottom  100px
    padding-top 120px
    .showTopName
        padding-bottom  50px
.leftNumber
    width: 130px;
    height: 120px;
    background:#fff;
    border:1px solid #FFFFFF;
    border-right:none;
    box-sizing: border-box;
    line-height 120px
    text-align: center;
    font-size: 35px;
    color: #0173F3;

.sanjiao
    width   0
    height  0
    border-right 100px solid #FFFFFF
	border-top 100px solid #FFFFFF
	border-bottom 100px solid #FFFFFF
.rightIcon
    width:0;
    height: 0;
    border-left: 12px solid #fff;
    border-top 12px solid transparent;
    border-bottom: 12px solid transparent;
    margin: 0 auto;
.fourItem
    display: flex;
    align-items: center;
    
.fourLIst
    width   100%
    height  100%
    display flex
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
.rightText
    width: 580px;
    height: 120px;
    border: 1px solid #FFFFFF;
    border-left: none;
    position:relative
    left -14px
    padding 14px 45px
    padding-left 30px
    box-sizing: border-box
    text-align: left;
    overflow hidden

.rightTextTitle
    font-size: 20px;
    color: #FFFFFF;
    padding-bottom  8px
.rightTextDec
    width: 536px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;

.sanjiao
    width:0;
    height: 0;
    border-top: 12px solid #fff;
    border-left 12px solid transparent;
    border-right: 12px solid transparent;
    margin: 0 auto;
.topTitle
    width: 1440px;
    height: 90px;
    background: #FFFFFF;
    text-align: center;
    font-size: 35px;
    color: #0173F3;
    line-height: 90px;
.showSwiperOnePosition
    display: flex;
    align-items: center
    width 100%
    height  100%
    justify-content: center;

.showSwiperOnes
    width   1440px
    height: 280px;
    border:1px solid #fff;

.joinUsText
    text-align: center;
    font-size: 15px;
    color: #FFFFFF;
    padding-top 30px


.fourthItem
    .about_main
        padding-top 104px
        box-sizing: border-box;
  
    .topTextInfo,.caseListNumber
        width 1490px
        margin: 0 auto;

  
    .chang
        width: 78px;
        height: 12px;
        border: 2px solid #0173F3;
    .aboutInfoLeft
        padding-top 80px
    .duan
        width: 34px;
        height: 5px;
        background: #0173F3;
        margin-top  3px
    .topIconTitleText
        text-align: left;
        padding-top 27px
    .title
        font-size: 50px;
        font-weight  600
        color: #333333;
        .showTextNums
            padding-top 40px
    .dec
        font-size: 20px;
        color: #333333;
    .topTextInfo
        display: flex;
        justify-content: space-between;
        padding-top  80px

    .aboutInfoRight
        width 724px
        height  565px
        img
            width 100%
    .topDec
        width: 475px;
        font-size: 15px;
        color: #666666;
        text-align: left;

    .firstTopDec
        padding-top 70px
        padding-bottom  30px

    .caseListNumber
        padding-top 150px

    .showThreeList
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center
        .showItem
            width 450px
            padding-top: 28px
            text-align: center
            border-left:  4px solid #80B9F9
            border-right: 4px solid #80B9F9
        .showTextTitle
            font-size: 30px;
            color: #333333;
            font-weight: 600;
        .showTextNum
            font-size: 80px;
            font-weight: bold;
            color: #0173F3;
            padding-top 28px


    .itemBox
        width 450px
        height  400px
        display: flex;
        align-items: center;
    .showItemBule
        width: 450px;
        height: 400px;
        background: #0173F3;
        text-align: center
    .buleTitle
        padding-top 40px
        padding-bottom  20px
        font-size: 24px;
        color: #FFFFFF;

    .buleDec
        margin: 0 auto;
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        div
            padding-top 8px
    .moreBtn
        width: 80px;
        font-size: 15px;
        margin: 0 auto;
        color: #FFFFFF;
        margin-top  27px
        text-decoration: underline;
        cursor pointer

    .map
        width: 593px;
        height: 310px;

    .showTellInfo
        display: flex;
        justify-content: space-between;
        width 1490px
        padding-top 50px
        align-items: center;

    .contact_dec_item
        display: flex;
        justify-content: flex-start;
        padding-top: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        align-items: center
        img
            width 22px
            height: 22px;
            margin-right  20px
            vertical-align: middle
    .contact_dec_item:first-child
        padding-top 20px
    .footer
        padding 34px 0
        background: #001D36;
        div
            padding-top 10px
            color #AAAAAA
            a
                color: #aaaaaa

    .showCaseListNumber
        padding-bottom  288px

    .showCaseListNumberBg
        background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/maps.png") no-repeat;
        background-size: 90% 90%;
        background-position-y: 120px;
        background-position-x: 40px;

.introduce
    text-align: left;

.introduceDec
    font-size: 30px;
    color: #333333;
.introduce
    font-size: 15px;
    color: #AAAAAA;
    padding 5px 0 15px

.introduceLine
    width: 25px;
    height: 4px;
    background: #0173F3;

.IndexMain
  width 100%
  min-width 1400px
  height 100%
  position relative

.textFloatInVideo
  position: absolute;
  top 80px
  width 100%
  padding-left  210px
  box-sizing: border-box;

.showLabel
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  letter-spacing: 10px;
  padding-top:354px;

.showTwoBtn
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top 190px
  .BtnStyle
    width: 240px;
    height: 60px;
    background: rgba(255,255,255,0.2);
    border-radius: 40px;
    border: 1px solid #6EA5FF;
    font-size: 24px;
    font-weight: 400;
    color: #6EA5FF;
    line-height: 60px;
    text-align: center;
    margin-right  150px
    margin-top  70px
    cursor pointer

.showName
  font-size: 85px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2B3455;
  text-align: left 
  letter-spacing: 35px;
  padding 230px 0 60px 

.textDecss
    width 100%
    text-align: left
    font-size: 30px;
    font-weight: 500;
    color: #2B3455;
    letter-spacing: 27px;

.showQrcode
    width:200px;
    height:200px;
    margin 0 auto
</style>