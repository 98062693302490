<template>
  <div class="mobileMain">
    <div class="heardNav">
        <div class="leftIcon" @click="show=true"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>
    <div class="productList">
        <div class="videoOrImg">
            <div class="showTitleName">新媒体运营</div>
            <div class="showTitleTwo">拍摄 / 策划 /宣传 / 演练</div>
            <div class="showTitleTwoDec">行业专家，工程技术，媒体服务三支团队，三支团队协同为项目提供一站式新媒体服务。行业专家对项目难点重点总结提升项目高度，
               策划现场会思路及方案。工程技术团队通过实地踏勘交流，从标准，创新，实用，
              规范角度提出现场整改应急演练意见及安全设计服务图纸。媒体团队以围绕项目亮点策划拍摄方案， 多维度，创意化呈现项目亮点故事，为项目提质增效。</div>
            <!-- <div class="showTitleTwoDec">结合用短视频/长视频/微电影等新媒体视频方式及图文素材，专业镜头指导、动态航拍大景、达芬奇调色渲染和三维特效，为项目安全工作增添异彩。</div> -->
            <div class="showTitleImg"  @click="setShowVideo(1)"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/showImg.png" alt=""></div>
            <div class="showTitleImg"  @click="setShowVideo(2)"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/videoColor.png" alt=""></div>
        </div>

        <div class="showMoreBtn" @click="seeMore">查看更多案例</div>
    </div>
    <van-popup v-model="show" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupop">
        <div class="showLeftPupopItem " @click="getUrl(0)">首页</div>
        <div class="showLeftPupopItem " @click="getUrl(1)">产品</div>
        <div class="showLeftPupopItem actives" >新媒体</div>
        <div class="showLeftPupopItem " @click="getUrl(3)">渠道加盟</div>
        <div class="showLeftPupopItem " @click="getUrl(4)">关于我们</div>
      </div>
    </van-popup>

     <van-popup v-model="showViode" position="center" closeable >
       <div class="showLeftPupop">
          <div class="showVider" v-if="showItem==1">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_3.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
          
          <div class="showVider" v-if="showItem==2">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_2.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


       </div>
    </van-popup>
  </div>
</template>

<script>
// var _czc = _czc || [];
// //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
// _czc.push(["_setAccount", "1280801298"]);
export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
        return{
          pupop:false,
          volume:0,
          show:false,
          showViode:false,
          showVideoStatus:false,
          showItem:0
        }
  },

  methods:{
    showVideo(){
      this.showVideoStatus=!this.showVideoStatus
    },
    getUrl(e){
      if(e==0){
        this.$router.push({name:'Home'})
      }
      if(e==1){
        this.$router.push({name:'m_product'})
      }
      if(e==2){
        this.$router.push({name:'m_video'})
      }
      if(e==3){
        this.$router.push({name:'m_join'})
      }
      if(e==4){
        this.$router.push({name:'m_aboutus'})
      }
    },
    seeMore(){
      this.$router.push({name:'m_videoInfo'})
    },
    setShowVideo(e){
      this.showViode=true
      this.showItem=e
    }
  },
  mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      let clientWidths= document.documentElement.clientWidth
      if(clientWidths>900){
         that.$router.push({name:'Home',params:{type:3}})
        }
    
      }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            setRem();
        };
        setRem();
        
    },
    
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.heardNav
  display flex
  justify-content flex-start
  align-items center
  padding 15px 25px
  position absolute
  top 0
  left: 0
  background #fff
  width 100%
  box-sizing  border-box
  z-index 99
  .leftIcon
    padding-right 24px
    img
      width 20px
      height  20px
      vertical-align: middle
  .rightName
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 2px;

.productList
    padding 60px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left

    img
        width   100%
.showTitleName
    font-size: 24px;
    color: #333333;
    font-weight 600

.showTitleTwo
    font-size: 16px;
    color: #333333;
    padding-top 5px

.showTitleTwoDec
    padding-top 20px
    line-height 30px
    font-size: 14px;
    color: #666666;

.showTitleImg
    margin-top  20px

.showMoreBtn
    width: 335px;
    height: 40px;
    background: rgba(255,255,255,0.2);
    border: 1px solid #333333;
    text-align: center;
    line-height 40px
    font-weight: 400;
    color: #333333;
    margin: 0 auto;
    margin-top  40px

.showLeftPupop
  max-width 400px
  min-width 350px
  margin: 0 auto;
  padding-top 34px
.showVider
  width 90%
  padding: 20px 0 40px
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
</style>
