<template>
  <div style="height:100%;">
    <!-- <div class="home"  @wheel.prevent="showWheel" @mousewheel="showWheel" v-if="showMobile&&pageNum<2"> -->
      
      <!-- <div class="home"  v-if="showMobile&&pageNum<5"> -->
      <!-- <heardNav @msg="getData" :pageNum="pageNum"></heardNav>
      <div class="index" v-if="pageNum==1"><concent @pupopStatus="getPupopStatus"></concent></div>
    </div> -->

    <div class="aboutUs" v-if="showMobile">
      <longIndex></longIndex>
    </div>

    <div  class="home" v-if="!showMobile">
      <Mindex></Mindex>
    </div>


      
  </div>
</template>


<script>
// @ is an alias to /src
import heardNav from '@/components/heardNav/index.vue'
import concent from '@/components/index/index.vue'
import product from '@/components/product/index.vue'
import viedo from '@/components/viedo/index.vue'
import aboutUs from '@/components/aboutUs/index.vue'
import joinUs from '@/components/join/index.vue'
import Mindex from '@/components/m-indexList/index.vue'
import longIndex from '@/components/indexlist/index.vue'

export default {
  name: 'Home',
  components: {
    heardNav,
    concent,
    product,
    viedo,
    aboutUs,
    joinUs,
    Mindex,
    longIndex
  },
  data(){
        return{
          run:true,
          index:'index',
          product:'product',
          showSelect:false,
          cheickWheel:"component-fade",
          pageNum:1,
          showPopup:false,
          showQrCodePopup:true,
          showMobile:true
        }
  },
  methods:{
    // showWheel(event){
    //   // console.log(event)
    //   let that=this
    //   that.showPopup=false
    //   if (!that.run) {
    //       return 
    //     // 如果开关关闭了，那就直接不执行下边的代码
    //     }
    //     this.showQrCodePopup=true
    //     that.run=false
    //     // 下下下下下下下
    //     if(event.deltaY>=1&&that.pageNum<5){
    //       that.pageNum=that.pageNum+1
    //     }
    //     // 上上上上上上上
    //     if(event.deltaY<=-1&&that.pageNum>1){
    //       that.pageNum=that.pageNum-1
    //     }
    //     // console.log(that.pageNum)
    //     setTimeout(function(){
    //        that.run=true
    //     },1200)
    // },

    showWheel(event){
      // console.log(event)
      let that=this
      that.showPopup=false
      if (!that.run) {
          return 
        // 如果开关关闭了，那就直接不执行下边的代码
        }
        this.showQrCodePopup=true
        that.run=false
        // 下下下下下下下
        if(event.deltaY>=1&&that.pageNum<5){
          that.pageNum=that.pageNum+1
        }
        // 上上上上上上上
        if(event.deltaY<=-1&&that.pageNum>1){
          that.pageNum=that.pageNum-1
        }
        // console.log(that.pageNum)
        setTimeout(function(){
           that.run=true
        },1200)
    },
    getData(msg){
        this.pageNum=msg
    },
    popupStatus(Status){

      this.showPopup=Status
    },
    hidePopup(){
      this.showPopup=false
    },
    showQrCode(){
      this.showQrCodePopup=!this.showQrCodePopup
    },
    getPupopStatus(event){
      // console.log(event)
      this.showQrCodePopup=event
    },
    //  handleScroll() {
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   var offsetTop = document.querySelector('#scroll-box').scrollTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
    //   console.log(scrollTop,offsetTop)
    //   if(offsetTop==0){
    //     this.pageNum=1
    //   }
    // },

    
  },
    mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      // console.log(window.screen.width)
      let clientWidths= document.documentElement.clientWidth
      console.log(clientWidths)
      if(clientWidths<1300&&clientWidths>990){
        that.showMobile=true
        const scale = clientWidths / 1920; // 750
        document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px"; 
        }
        if(clientWidths<990){
          that.showMobile=false
          const scale = clientWidths / 375; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        if(clientWidths>=1300){
          that.showMobile=true
          const scale = clientWidths / 1920; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        that.$forceUpdate()
        }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
          console.log('_________________')
          setRem();
        };
        setRem();

        // if(this.$route.params.type){
        //   this.pageNum=this.$route.params.type
        //   console.log(this.pageNum)
        // }
        // window.addEventListener('scroll', this.handleScroll, true);
    },
    // destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    //   window.removeEventListener('scroll', this.handleScroll)
    // },

}
</script>
<style lang="stylus">
.home
  height 100%
  position relative
  overflow hidden

.product,.index,.viedo,.aboutUs
  position absolute
  top 0px
  width 100%
  height: 100%
  background: #fff;
  overflow hidden
.index
  z-index 14
  

.product
  z-index 15

.viedo
  z-index 16

.aboutUs
  z-index 17
  width 100%
  min-width 1440px
  overflow auto
.component-enter-active 
  animation: ente .25s ease-out;

.component-leave-active 
  animation: leaves .25s ease-out;

@keyframes ente {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes leaves {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
   transform: translateY(100%);
    opacity: 0;
  }
}


.join_Popup
    position fixed
    height  100%
    width   350px
    box-shadow: 0px 2px 60px 0px rgba(1, 159, 254, 0.3);
    right 0
    top 0
    z-index 999
    background  #fff

.hideBtn
  width 140px
  height 50px
  border-radius: 25px
  border 1px solid #333333
  font-size 22px
  font-weight 500
  color #333333
  line-height 50px
  text-align  center
  margin 140px auto 120px
  cursor pointer

.qecodetitle
  float left
  border-bottom 4px solid #000E2D
  margin-left 44px
  padding-bottom  2px
  font-size: 20px;
  font-weight: 500;
  color: #333333;

.qrcodeImg
  width 266px
  height  266px
  margin 48px auto
  img 
    width 100%
    height  100%

.tel
  margin-top  100px
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 42px;

.qrcode_popup
  width 100px
  height 100px
  background rgba(0, 0, 0, 0.5)
  border-radius 10px
  position fixed
  right 50px
  top 40%
  z-index 20
  font-size 18px
  font-weight 600
  color: #FFFFFF
  letter-spacing 2px
  font-weight 600
  cursor pointer

.scanIcon
  width 50px
  height  50
  margin  6px auto  4px
  img
    width 100%
    height  100%

.qrcode_popup_img
  width 300px
  height  500px
  background rgba(0, 0, 0, 0.5)
  border-radius 10px
  position fixed
  right 50px
  top 28%
  z-index 20
  font-size 18px
  font-weight 600
  color: #FFFFFF
  letter-spacing 2px
  font-weight 600
  padding-top 30px
  box-sizing  border-box
  img
    width 100%
    height  100%
  
  .qrcode_img_item
    width 180px
    height  180px
    margin  0 auto
.qrcode_name
  margin-top  6px
.shows
  position relative

.jiantou
  position  absolute
  right 26px
  top 50%
  font-size 18px
  color #fff
  cursor pointer

.mask
  width 100%
  height 100%
  position fixed
  left 0
  top 0
  z-index 999

#cnzz_stat_icon_1280801298
  display none

</style>