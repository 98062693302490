<template>
  <div class="about_main">

    <div class="topTextInfo">
      <div class="aboutInfoLeft">
        <div class="topIconTitle">
          <div class="topIcon">
            <div class="chang"></div>
            <div class="duan"></div>
          </div>
          <div class="topIconTitleText">
            <div class="title">关于我们</div>

          </div>

          <div class="topDec firstTopDec">重交智安（浙江）科技发展有限公司 是重庆交大交通安全科技研究院有限公司旗下专设，主营业务就是安全教育服务。</div>
          <div class="topDec">公司基于重庆交通大学的内外部的专家及师资力量，以及重庆交大安科院多年来丰富的市场实践经验， 通过多年的实地调研和针对性考察，
            最终确定了安全教育的方向和具体实施方案， 2021年6月“安知鹊”平台应运而生，8月正式面向市场。</div>
        </div>

      </div>
      <div class="aboutInfoRight"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/rightImg.png" alt=""></div>
    </div>

    <div class="caseListNumber showCaseListNumberBg">
        <div class="topIconTitle">
          <div class="topIcon">
            <div class="chang"></div>
            <div class="duan"></div>
          </div>
          <div class="topIconTitleText">
            <div class="title">合作案例</div>
 
          </div>
        </div>

        <div class="showTextNums">
          <div class="showThreeList">
            <div class="itemBox" style="height: 400px;" @mouseover="showPoster(0)"  @mouseleave="handleHiedprice">
              <div class="showItemBule" style="height: 400px;" v-if="showDecList[0]">
                <div class="buleTitle">安全咨询&评价</div>
                <div class="buleDec">
                  <div>广西交投安全生产检查考核</div>
                  <div>国道310线安全管理咨询</div>
                  <div>G213 线安全顾问服务</div>
                  <div>临金高速公路用电方案咨询</div>
                  <div>沪蓉高速公路速度论证咨询</div>
                  <div>G15沈海高速施工图安全评价</div>
                  <div>叙古高速公路运营安全评价</div>
                  <div>成巴高速安全评价</div>
                </div>
                <!-- <div class="moreBtn">更多案例</div> -->
              </div>
              <div class="showItem" style="border:none;"  v-if="!showDecList[0]">
                <div class="showTextTitle">安全咨询&评价</div>
                <div class="showTextNum">150+</div>
              </div>
            </div>


            <div class="itemBox" style="height: 400px;" @mouseover="showPoster(1)"  @mouseleave="handleHiedprice">
              <div class="showItemBule" style="height: 400px;" v-if="showDecList[1]">
                  <div class="buleTitle">数字化系统</div>
                  <div class="buleDec">
                    <div>临晋高速TJ03段安全教育平台</div>
                    <div>天峨巴马高速公路教育平台</div>
                    <div>大新凭祥高速公路安全管理平台</div>
                    <div>宁波六横公路大桥安全管理平台</div>
                    <div>杭甬复线宁波一期安全管理平台</div>
                    <div>岳圩口岸公路数智管理平台</div>
                    <div>宁波昆亭塘溪段安全管理平台</div>
                    <div>国道235TJ01段安全管理平台</div>
                  </div>
                  <!-- <div class="moreBtn">更多案例</div> -->
              </div>
              <div class="showItem" v-if="!showDecList[1]">
                  <div class="showTextTitle">数字化系统</div>
                  <div class="showTextNum">25+</div>
              </div>
            </div>
            <div class="itemBox" style="height: 400px;" @mouseover="showPoster(2)"  @mouseleave="handleHiedprice">
              <div class="showItemBule" style="height: 400px;" v-if="showDecList[2]">
                  <div class="buleTitle">新媒体服务</div>
                  <div class="buleDec">
                    <div>巴马凭祥应急演练</div>
                    <div>诸暨235国道坍塌应急演练</div>
                    <div>广西田巴高速品质工程宣传片</div>
                    <div>杭绍甬项目安全警示片</div>
                    <div>隧道工厂工点化技术交底视频</div>
                    <div>九套台隧道施工工艺纪录片</div>
                    <div>台州朱溪至白塔工程现场会</div>
                    <div>桂金高速现场会</div>
                  </div>
                  <!-- <div class="moreBtn">更多案例</div> -->
              </div>
              <div class="showItem" style="border:none;" v-if="!showDecList[2]"> 
                <div class="showTextTitle">新媒体服务</div>
                <div class="showTextNum">30+</div>
              </div>
            </div>
          
          </div>
        </div>

    </div>


    <div class="caseListNumber showCaseListNumber">
        <div class="topIconTitle">
          <div class="topIcon">
            <div class="chang"></div>
            <div class="duan"></div>
          </div>
          <div class="topIconTitleText">
            <div class="title">联系我们</div>
            、
          </div>
        </div>

        <div class="showTellInfo">
          
          <div class="maps">
            <baidu-map class="map" :center="{lng:120.262759, lat:30.237565}" :zoom="18">
              <bm-circle :center="{lng:120.262459, lat:30.237365}" :radius="circlePath.radius" fill-color="#FB5B4E" :stroke-opacity="0.3" :stroke-weight="2" :editing="false"></bm-circle>
              <bm-marker :position="{lng:120.262459, lat:30.237265}" ></bm-marker>
            </baidu-map>
          </div>

            <div class="showDetailInfo">
              <div class="contact_dec_item" ><img src="../../assets/icon/address.png" alt=""> 浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601室</div>
              <div class="contact_dec_item"><img src="../../assets/icon/phone.png" alt=""> 199 0671 1623 / 199 0671 1626</div>
              <div class="contact_dec_item"><img src="../../assets/icon/email.png" alt=""> anzhiquecq@163.com</div>
              <!-- <div class="contact_dec_item"><img src="../../assets/icon/qq.png" alt=""> 258506508</div> -->
              <div class="contact_dec_item"><img src="../../assets/icon/WeChat.png" alt=""> 微信公众号：安知鹊安全课堂</div>
            </div>
          </div>


    </div>


  <div class="footer">
      <div> 2021@重交智安  | 
        <a href="https://beian.miit.gov.cn/?token=9c9d2aa2-7eed-4bdd-87c5-d4e33ea46caf#/Integrated/index" target=”_blank”> 浙ICP备2021035454号 </a> 
        |  重交智安（浙江）科技发展有限公司
      </div>        
      <div>中国互联网举报中心  |  计算机软件著作权证书号：8180649号  |  客户服务热线：0571-86885135  |  违法和不良信息举报：0571-86885135  |  举报邮箱：753221776@qq.com</div>
      <div> 地址：浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601、1602、1603、1604室 营业执照 </div>
  </div>

  </div>
</template>



<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'  //Circle
import BmCircle from 'vue-baidu-map/components/overlays/Circle.vue'  //Circle

export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {
    BaiduMap,
    BmMarker,
    BmCircle
  },
  data(){
    return{
      circlePath: {
        // center: {
        //   lng: 120.256059,
        //   lat: 30.231271
        // },
        
        radius: 50
      },
      showDecList:[true,false,false],
    }
  },

  mounted() {
    
  },
  methods:{
    showPoster(e){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      that.showDecList[e]=true
      this.$forceUpdate()
    },


    handleHiedprice(){
      let that=this
      for (const key in that.showDecList) {
        that.showDecList[key]=false
      }
      this.$forceUpdate()
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.about_main
  padding-top 104px
  box-sizing: border-box;
  
.topTextInfo,.caseListNumber
  width 1490px
  margin: 0 auto;
  
.chang
  width: 78px;
  height: 12px;
  border: 2px solid #0173F3;
.aboutInfoLeft
  padding-top 80px
.duan
  width: 34px;
  height: 5px;
  background: #0173F3;
  margin-top  3px
.topIconTitleText
  text-align: left;
  padding-top 27px
.title
  font-size: 50px;
  font-weight  600
  color: #333333;
.showTextNums
  padding-top 40px
.dec
  font-size: 20px;
  color: #333333;
.topTextInfo
  display: flex;
  justify-content: space-between;

.aboutInfoRight
  width 724px
  height  565px
  img
    width 100%
.topDec
  width: 475px;
  font-size: 15px;
  color: #666666;
  text-align: left;

.firstTopDec
  padding-top 70px
  padding-bottom  30px

.caseListNumber
  padding-top 150px

.showThreeList
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center
.showItem
  width 450px
  padding-top: 28px
  text-align: center
  border-left:  4px solid #80B9F9
  border-right: 4px solid #80B9F9
  .showTextTitle
    font-size: 30px;
    color: #333333;
    font-weight: 600;
  .showTextNum
    font-size: 80px;
    font-weight: bold;
    color: #0173F3;
    padding-top 28px


.itemBox
  width 450px
  height  400px
  display: flex;
  align-items: center;
.showItemBule
  width: 450px;
  height: 400px;
  background: #0173F3;
  text-align: center
.buleTitle
  padding-top 40px
  padding-bottom  20px
  font-size: 24px;
  color: #FFFFFF;

.buleDec
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
  color: #FFFFFF;
  div
    padding-top 8px
.moreBtn
  width: 80px;
  font-size: 15px;
  margin: 0 auto;
  color: #FFFFFF;
  margin-top  27px
  text-decoration: underline;
  cursor pointer

.map
  width: 593px;
  height: 270px;

.showTellInfo
  display: flex;
  justify-content: space-between;
  width 1490px
  padding-top 50px

.contact_dec_item
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  align-items: center
  img
    width 22px
    height: 22px;
    margin-right  20px
    vertical-align: middle
.contact_dec_item:first-child
  padding-top 20px
.footer
  padding 34px 0
  background: #001D36;
  div
    padding-top 10px
    color #AAAAAA
    a
      color: #aaaaaa

.showCaseListNumber
    padding-bottom  288px

.showCaseListNumberBg
  background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/maps.png") no-repeat;
  background-size: 90% 90%;
  background-position-y: 120px;
  background-position-x: 40px;
</style>
