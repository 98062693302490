<template>
  <div class="m_alllist" id="scroll-box">
    <div class="heardNav">
        <div class="leftIcon" @click="getUrl(2)"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>


    <div class="videoOrImg" :style="{'height':height+'px'}">
        <div class="showVideo">
            <!-- <div class="videoCss" v-if="showVideoStatus">
            <video id="audioPlayer"  
                src="https://cdn.anzhique.com/mp4/video_1.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
            </video>
            </div> -->
            <div class="showIndexText">
            <div class="showIndexTextPopup">
                <div class="showIndexTextPopupTitle">安知鹊</div>
                <div class="showIndexTextPopupDec">打通安全生产最后一公里</div>
                <!-- <div class="showTwoBtn">
                <div class="btnItem">下载APP</div>
                <div class="btnItem">使用小程序</div>  
                </div> -->
            </div>
            </div>
        </div>
    </div>

    <div class="productList" id="productList">
        <div class="productItem">
            <div class="productItemBtn">
                <div class="productName">安全数智管理平台</div>
                <div class="productBtn" @click="setProductShow(0)" :style="{opacity:!productList[0]? 1:0}" >查看详情 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/zhankai.png" alt=""></div>
            </div>
        </div>
        <div class="productDec" v-if="productList[0]">
            <div class="productDecText">提供企业级项目施工全过程安全数智化监管服务，包含工程信息数智化，施工计划管理，标准安全防护措施，风险动态管控及预警，
                现场人员/机械设备及车辆管控等，全方位提升项目工程安全信息化。</div>
            <div class="productDecBtn" @click="setProductHidden(0)">收起 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/shouqi.png" alt=""></div>
        </div>



        <div class="productItem productItemF">
            <div class="productItemBtn ">
                <div class="productName">双重预防管理平台</div>
                <div class="productBtn" @click="setProductShow(1)" :style="{opacity:!productList[1]? 1:0}">查看详情 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/zhankai.png" alt=""></div>
            </div>
        </div>
        <div class="productDec" v-if="productList[1]">
            <div class="productDecText">结合施工计划主动识别风险并及时预警，风险处理落实到人，闭环流程处理机制。形成电子档案及汇报材料，实现管理留痕可追溯。
                系统内置8000余条隐患处理措施，保障处理人员快速妥善进行问题处理。实现安全隐患治理“信息化” “可视化” “数字化” “清单化” “闭环化” 。</div>
            <div class="productDecBtn" @click="setProductHidden(1)">收起 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/shouqi.png" alt=""></div>
        </div>



        <div class="productItem productItemT">
            <div class="productItemBtn ">
                <div class="productName">人员管理及教育平台</div>
                <div class="productBtn" @click="setProductShow(2)" :style="{opacity:!productList[2]? 1:0}">查看详情 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/zhankai.png" alt=""></div>
            </div>
        </div>
        <div class="productDec" v-if="productList[2]">
            <div class="productDecText">人员管理结合安全培训，以信息数字化手段实现对施工人员的日常工作管控，将安全生产落实到每一名一线作业人员。系统内置庞大的视频课件及试卷库，
                包含三级教育，班前会，日常安全培训，覆盖各类工种。所有人员信息，学习及考试台账均可下载，线下归档留存。</div>
            <div class="productDecBtn" @click="setProductHidden(2)">收起 <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/shouqi.png" alt=""></div>
        </div>

    </div>
    <!-- 新媒体运营 -->
    <div class="caseList" >
        <div class="caseToptitle" id="videoList">
            <div class="topTitles">新媒体服务</div>
            <div class="topTitleLabel">拍摄 / 策划 /宣传 / 演练</div>
            <div class="textDecFirst">行业专家，工程技术，媒体服务三支团队，三支团队协同为项目提供一站式新媒体服务。行业专家对项目难点重点总结提升项目高度，策划现场会思路及方案。</div>
        </div>
          <div class="twoVideo">
                <div class="showImgItem">
                    <div class="imgItem" >
                        <div class="img" >
                        <img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_indeVideo1.png" alt="">

                        </div>
                        <div class="label">
                            <div class="text_center_mask" @click="setShowVideo(1)" >
                                <div class="playIcon"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/playIcon.png" alt=""></div>
                                <div class="playBtn">案例视频</div>
                            </div>
                        </div>
                    </div>
                    <div class="imgItem"  >
                        <div class="img">
                        <img  src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_indeVideo.png" alt="">
                        </div>
                        <div class="label">
                            <div class="text_center_mask" @click="setShowVideo(2)">
                                <div class="playIcon"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/playIcon.png" alt=""></div>
                                <div class="playBtn">案例视频</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="videoList">
                <div class="showCaseMore" @click="showTwoStage">查看更多视频</div>
            </div>
            
    </div>

    <div class="joinUsConent" id="joinUs">
        <div class="swiper_box">
            <div class="swiper-container ">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide">
                        <div class="showSwiperOne showSwiperOnePosition">
                            <div class="topTitle">渠道加盟</div>
                            <div class="topSecend">一、国家政策</div>
                            <div class="topSecendDec">国家目前已经将安全生产纳入安全生产法和刑法，
                                安全生产费也由原先的1.5%提到 2%，并实行全员安全生产责任制，足以看出国家对施工安全的重视性！</div>
                            <div class="topSecend">二、产品优势</div>
                            <div class="topSecendDec">产品是由业内高知名度、高专业度、高影响力的专家团队倾力打造，提供安全教育、安全管理、
                                安全咨询、三位一体的服务方案，赋能企业安全工作智慧升级！</div>
                            <div class="topSecend">三、产品亮点</div>
                            <div class="topSecendDec">采用feed流的形式以及千人千面的算法根据岗位工种的不同，提供课程，课件内容为真实场景实拍针对性的采用先错后对的拍摄方式，
                                 有效提高工人的安全意识，避免安全隐患，用户可通过碎片化时间学习，大大提升了学习的便捷性。</div>
                            <div class="topSecend">四、加盟保证</div>
                            <div class="topSecendDec">
                                <div>1、我们为加盟商提供我们的开拓思路，拟定一致的发展战略体系。</div>
                                <div>2、为每一个加盟商提供正式运营、营运发展培训，提供细致周到的咨询和服务。</div>
                                <div>3、我们将定期组织产品培训与研讨，为共同的发展提供可靠的保证。</div>
                            </div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="showSwiperOne ">
                            <div class="showSwiperOnes">
                                <div class="topTitle">加盟须知</div>
                                <div class="topSecend">01</div>
                                <div class="topSecendDec">授权经销商拥有报备项目的独家经营权，该客户独家服务对接锁定期为期2个月;
                                    如2个月内末能达成合作，其他授权经销商向公司报备可以进行对接合作。
                                    (如非经销商原因造成的项目延期，经销商可书面申请项目延期，审核通过后，可顺延项目报备时长。)</div>
                                <div class="topSecend">02</div>
                                <div class="topSecendDec">享受公司提供的完整的人员培训，包括技术培训、产品培训、销售培训、标准化管理培训等:</div>
                                <div class="topSecend">03</div>
                                <div class="topSecendDec">公司免费提供经营期间的营销策略及技术指导</div>
                                <div class="topSecend">04</div>
                                <div class="topSecendDec">公司提供会议支持，经销商举办招商会或招商沙龙:(经销商申请并提供方案,经公司核实后,视情况而定。</div>
                                <div class="topSecend">05</div>
                                <div class="topSecendDec">业务支持，经销商举办产品说明会或重点客户汇报工作;(经销商申请并提供方案,经公司核实后,视情况而定。）</div>
                                <div class="topSecend">06</div>
                                <div class="topSecendDec">广告支持，经销商根据市场情况决定区域性广告投放(经销商申请并提供方案,经公司核实后,视情况而定，广告投放期间，该区域未报备业务优先分配给该经销商。</div>
                                <div class="topSecend">07</div>
                                <div class="topSecendDec">其他根据平台业务发展实际情况，经双方另外约定的事项。</div>
                            </div>
                        </div>
                     
                    </div>
                </div>
                
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
    <div class="maboutUs" id="aboutUs">

        <div class="productList">
            <div class="showTitleName">关于我们</div>
            <div class="showAboutA"></div>
            <div class="brs"></div>
            <div class="showDec">重交智安（浙江）科技发展有限公司是重庆交大交通安全科技研究院有限公司旗下专设，主营业务就是安全教育服务。</div>
            <div class="showDec">公司基于重庆交通大学的内外部的专家及师资力量，以及重庆交大安科院多年来丰富的市场实践经验，
                通过多年的实地调研和针对性考察，最终确定了安全教育的方向和具体实施方案， 2021年6月“安知鹊”平台应运而生，8月正式面向市场。</div>
            <div class="showImgs"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/rightImg.png" alt=""></div>

            <div class="showCasePupop">
                <div class="showTitleName">合作案例</div>
                <div class="showThreeList">
                    <div class="showThreeItem">
                        <div class="title">安全咨询&评价</div>
                        <div class="buleNum">150+</div>
                        <div class="showBtn" @click="setCaseNumber(1)">查看案例</div>
                    </div>
                    <div class="showThreeItem">
                        <div class="title">数字化系统</div>
                        <div class="buleNum">25+</div>
                        <div class="showBtn" @click="setCaseNumber(2)">查看案例</div>
                    </div>

                    <div class="showThreeItem">
                        <div class="title">新媒体服务</div>
                        <div class="buleNum">150+</div>
                        <div class="showBtn" @click="setCaseNumber(3)">查看案例</div>
                    </div>
                </div>
                <div class="showPupop" v-if="showCaseDec">
                    <div class="firsTextPop" v-if="caseNumber==1">
                        <div class="showRightIcon" @click="showCaseDec=false"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/btnOffIcon.png" alt=""></div>
                        <div class="caseLists">
                            <div class="caseItem">广西交投安全生产检查考核</div>
                            <div class="caseItem">国道310线安全管理咨询</div>
                            <div class="caseItem">G213 线安全顾问服务</div>
                            <div class="caseItem">临金高速公路用电方案咨询</div>
                            <div class="caseItem">沪蓉高速公路速度论证咨询</div>
                            <div class="caseItem">G15沈海高速施工图安全评价</div>
                            <div class="caseItem">叙古高速公路运营安全评价</div>
                            <div class="caseItem">成巴高速安全评价</div>
                        </div>
                    </div>
                    <div class="firsTextPop" v-if="caseNumber==2">
                        <div class="showRightIcon" @click="showCaseDec=false"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/btnOffIcon.png" alt=""></div>
                        <div class="caseLists">
                            <div class="caseItem">临晋高速TJ03段安全教育平台</div>
                            <div class="caseItem">天峨巴马高速公路教育平台</div>
                            <div class="caseItem">大新凭祥高速公路安全管理平台</div>
                            <div class="caseItem">宁波六横公路大桥安全管理平台</div>
                            <div class="caseItem">杭甬复线宁波一期安全管理平台</div>
                            <div class="caseItem">岳圩口岸公路数智管理平台</div>
                            <div class="caseItem">宁波昆亭塘溪段安全管理平台</div>
                            <div class="caseItem">国道235TJ01段安全管理平台</div>
                        </div>
                    </div>
                     <div class="buleDec" v-if="caseNumber==3">
                        <div class="showRightIcon" @click="showCaseDec=false"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/btnOffIcon.png" alt=""></div>
                        <div class="caseLists">
                            <div class="caseItem">巴马凭祥应急演练</div>
                            <div class="caseItem">诸暨235国道坍塌应急演练</div>
                            <div class="caseItem">广西田巴高速品质工程宣传片</div>
                            <div class="caseItem">杭绍甬项目安全警示片</div>
                            <div class="caseItem">隧道工厂工点化技术交底视频</div>
                            <div class="caseItem">九套台隧道施工工艺纪录片</div>
                            <div class="caseItem">台州朱溪至白塔工程现场会</div>
                            <div class="caseItem">桂金高速现场会</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="showTitleName">联系我们</div>
            <div class="showAboutA"></div>

            <div class="maps">
                <baidu-map class="map" :center="{lng:120.262759, lat:30.237565}" :zoom="18">
                <!-- <bm-circle :center="{lng:120.262459, lat:30.237365}" :radius="circlePath.radius" fill-color="#FB5B4E" :stroke-opacity="0.3" :stroke-weight="2" :editing="false"></bm-circle> -->
                <bm-marker :position="{lng:120.262459, lat:30.237265}" ></bm-marker>
                </baidu-map>
            </div>
            <div class="showDetailInfo">
                <div class="contact_dec_item" ><img style="vertical-align:middle;" src="../../assets/icon/address.png" alt=""> 浙江省杭州市萧山区宁围街道富业巷3号浙江民营企业发展大厦1幢1601室</div>
                <div class="contact_dec_item"><img src="../../assets/icon/phone.png" alt=""> 199 0671 1623 / 199 0671 1626</div>
                <div class="contact_dec_item"><img src="../../assets/icon/email.png" alt=""> anzhiquecq@163.com</div>
                <!-- <div class="contact_dec_item"><img src="../../assets/icon/qq.png" alt=""> 258506508</div> -->
                <div class="contact_dec_item"><img src="../../assets/icon/WeChat.png" alt=""> 微信公众号：重安视界</div>
                <div class="showQrcode"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/qrcode.png" alt=""></div>
            </div>
        </div>
        
    </div>


    <van-popup v-model="showViode" close-on-click-overlay="false"  @close="closes" position="center" closeable >
       <div class="showLeftPupop">
          <div class="showVider" v-if="showItem==1">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_3.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
          
          <div class="showVider" v-if="showItem==2">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://cdn.anzhique.com/mp4/video_2.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
       </div>
    </van-popup>
    <van-popup v-model="showPuppo" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupopS">
        <div class="showLeftPupopItem " @click="getUrl(1)">首页</div>
        <div class="showLeftPupopItem " @click="getUrl('productList')">产品</div>
        <div class="showLeftPupopItem " @click="getUrl('videoList')">新媒体</div>
        <div class="showLeftPupopItem " @click="getUrl('joinUs')">渠道加盟</div>
        <div class="showLeftPupopItem " @click="getUrl('aboutUs')">关于我们</div>
      </div>
    </van-popup>



  </div>
</template>

<script>
import Swiper, { Pagination, Navigation, Autoplay, EffectCards } from 'swiper' // js 模块
import 'swiper/swiper-bundle' // css 模块swiper/css/effect-cards
import 'swiper/swiper-bundle.min.css'
Swiper.use([Pagination, Navigation, Autoplay,EffectCards])
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'  //Circle
import BmCircle from 'vue-baidu-map/components/overlays/Circle.vue'  //Circle


export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
    return{
        pupop:false,
        volume:0,
        productList:[false,false,false],
        showViode:false,
        showItem:0,
        showPuppo:false,
        height:0,
        showCaseDec:false,
        circlePath: {
            // center: {
            //   lng: 120.256059,
            //   lat: 30.231271
            // },
            radius: 50
        },
        caseNumber:1
    }
  },

  methods:{
    showQrCode(){
      this.$emit('pupopStatus',this.pupop)
      this.pupop=!this.pupop
    },
    setProductShow(e){
  
        this.productList[e]=true
        this.$forceUpdate()
    },
    setProductHidden(e){
        this.productList[e]=false
         this.$forceUpdate()
    },

    closes(){
      // console.log(222222)
      this.$refs.audioPlayer.pause()
    },
    
    showTwoStage(){

    //   this.$router.push({name:'vieeoList',params:""})
      let routeData=this.$router.resolve({name:'videoList1',params:""})
        window.open(routeData.href, '_blank')
    },
    setShowVideo(e){
      this.showViode=true
      this.showItem=e
    },
    getUrl(id){
        this.showPuppo=!this.showPuppo
        if(id!=1&&id!=2){
            const box = document.getElementById('scroll-box')
            let scrollItem = document.getElementById(id)
            // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
            this.offsetTop = scrollItem.offsetTop
            box.scrollTo({
                top: parseFloat(scrollItem.offsetTop)-50,
                behavior: "smooth",
            });
        }
        if(id==1){
            const box = document.getElementById('scroll-box')
            box.scrollTo({
                top:0,
                behavior: "smooth",
            });
        }
    },
    setCaseNumber(e){
        console.log(e)
        this.showCaseDec=true
        this.caseNumber=e
    }

  },

  mounted() {
    let that=this
    let height=document.body.clientHeight
    this.height=height
    //   this.showSelect=this.showAll[0]
      this.showSelect=this.productList[0]
      const swiper = new Swiper('.swiper-container', {
      // direction: 'vertical',

      loop: true,
      effect: "Pagination",
      speed: 500,
      spaceBetween: 0,
      centeredSlides: false,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        hideOnClick:false,
        type:'bullets',
        clickable: true // 允许分页点击跳转
      },
    //   cardsEffect: {
    //     slideShadows: false,
    //     transformEl:'.text',
    //   },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
       on: {
       slideChangeTransitionStart: function(activeIndex){
          that.setectPrice=this.activeIndex+1
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        hideOnClick: true,
        hiddenClass: 'my-button-hidden',
      }
    })
    
    window.onresize = () => {
      if(document.documentElement.clientWidth>1300){
            const swiper = new Swiper('.swiper-container', {
            // direction: 'vertical',

            loop: true,
            effect: "Pagination",
            speed: 500,
            spaceBetween: 0,
            centeredSlides: false,
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
                hideOnClick:false,
                type:'bullets',
                clickable: true // 允许分页点击跳转
            },
            //   cardsEffect: {
            //     slideShadows: false,
            //     transformEl:'.text',
            //   },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
            on: {
                slideChangeTransitionStart: function(activeIndex){
                    that.setectPrice=this.activeIndex+1
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                hideOnClick: true,
                hiddenClass: 'my-button-hidden',
            }
            })
        }
          
      };
      this.swiper=swiper
      
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

.m_alllist
    padding-bottom  20px
    padding-top 60px
    height  100%
    overflow: auto;
    box-sizing: border-box;
    .productItem
        width   100%
        height  225px
        background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_index1.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
    .productItemF
        background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_index2.png") no-repeat;
        background-size: 100% 100%;
    .productItemT
        background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_index3.png") no-repeat;
        background-size: 100% 100%;
    .productBtn
        padding-top 14px;
        img
            width   16px
            height  16px
            margin-left: 10px;
    .productName

        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    .productBtn
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        justify-content center
        align-items: center;
    
    .productDecText
        width: 340px;
        font-size: 14px;
        color: #666666;
        line-height: 35px;
        margin: 0 auto;
        text-align: left;
    
    .productDecBtn
        display: flex;
        justify-content center
        align-items: center;
        margin-top  40px
        img
            width   16px
            height  16px

    .productDec
        padding-top 10px
        padding-bottom  40px
    
    .caseList
        text-align  left
        width   100%
        .caseToptitle
            width   100%
            padding 20px
            box-sizing: border-box;
        .topTitles
            font-size: 24px;
            color: #333333;
            font-weight 600
        .topTitleLabel
            font-size: 16px;
            color: #333333;
            font-weight 600
            padding 6px 0 0

        .textDecFirst
            font-size: 14px;
            color: #666666;
            line-height: 35px;
            padding-top 20px

        .showImgItem
            display flex
            text-align: left;
        
        .imgItem
            width 100%
            height  130px
            position relative
            .img
                width   100%
                height  100%
                img
                    width 100%
                    height: 100%;
            .label
                position: absolute;
                left    0
                top 0
                display: flex;
                justify-content: center;
                align-items: center;
                width 100%
                height  100%
            .text_center_mask
                width 100%
                text-align: center;
            .playIcon
                width 20px
                height  20px 
                margin: 0 auto 10px;
                img
                    width   100%
                    height: 100%;
            .playBtn
                font-size: 12px;
                color: #FFFFFF;
    .showCaseMore
        width: 335px;
        height: 40px;
        background: rgba(255,255,255,0.2);
        border: 1px solid #333333;
        margin: 0 auto;
        box-sizing: border-box;
        text-align: center;
        line-height 40px
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    .videoList
        padding-top 30px
    
    .topTitle
        font-size: 24px;
        color: #333333;
        font-weight: 600;
        padding-bottom  10px
       
    .topSecend
        font-size: 16px;
        color: #333333;
        font-weight 600
        padding 20px 0
    .topSecendDec
        font-size: 14px;
        color: #666666;
        line-height: 25px;
    .showSwiperOne
        text-align: left;
        padding 20px
    .joinUsConent
        width   100%
        overflow hidden
    
    .showSwiperOnePosition
        .topSecendDec
            line-height 36px
    .swiper_box
        padding-top 40px
        position relative


    .maboutUs
        .productList
            padding 20px 20px   60px
            width   100%
            box-sizing: border-box;
            overflow hidden
            text-align  left
            img
                width   100%
        .showTitleName
            font-size: 24px;
            color: #333333;
            font-weight 600
            padding-top 60px

        .showTTitle
            font-size: 16px;
            font-weight 600
            color: #333333;
            padding-top 20px
            padding-bottom  6px

        .showTDec
            font-size: 14px;
            color: #666666;
            line-height 30px
        .showAboutA
            font-size: 16px;
            color: #333333;
            padding-top 5px

        .showThreeList
            display: flex;
            justify-content: space-between;
            text-align: center;
            .title
                padding-top 45px
                font-size: 14px;
                color: #333333;
                font-weight 600
            .buleNum
                font-size: 30px;
                font-weight: bold;
                color: #0173F3;
                padding 15px 0
            .showBtn

                font-size: 12px;
                text-decoration: underline
                color: #666666;
        .brs
            padding-top 20px
        .showDec
            font-size: 14px;
            color: #666666;
            line-height: 34px;
            padding-bottom  10px

        .maps
            width   100%
            height  200px
            margin-top  20px
            .map
                width   100%
                height  100%

        .contact_dec_item
            display flex
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            align-items: center;
            margin-top  15px
            img
                width   16px
                height  16px
                margin-right 16px

        .showDetailInfo  
            padding-top 35px
    .showLeftPupop
        max-width 400px
        min-width 350px
        margin: 0 auto;
        padding-top 34px
    .showVider
        width 90%
        padding: 20px 0 40px
        margin: 0 auto;
        display:flex;
        align-items: center
        video
            width:100%;
    .heardNav
        display flex
        justify-content flex-start
        align-items center
        padding 15px 25px
        position fixed
        top 0
        left: 0
        background #fff
        width 100%
        box-sizing  border-box
        z-index 99
        .leftIcon
            padding-right 24px
            img
                width 20px
                height  20px
                vertical-align: middle
        .rightName
            font-size: 15px;
            font-weight: 600;
            color: #333333;
            letter-spacing: 2px;

.showIndexText
  padding-top 60px
  position relative
.showFffFont
  text-align: center
  margin: 0 auto;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  flex-direction column
  justify-content: space-between
  height: 60%

.showVideo
  width 100%
  background: url("https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/m_indexBg.png") no-repeat;
  background-size:100% 100%;
  height    100%
  video 
    width 100%
    height  100%;

.showIndexTextPopupTitle
    font-size: 45px;
    font-weight: 600;
    color: #2B3455;
    letter-spacing: 18px;
    padding-top 140px


.showIndexTextPopupDec
    font-size: 16px;
    font-weight: 500;
    color: #2B3455;
    letter-spacing: 9px;
    padding 45px 0 30px

.showQrcode
    width   140px
    height  140px
    margin 0 auto
    img
        width 100%
        height  100%

.showCasePupop
    position relative
    padding-bottom  60px
    padding-top 20px

.showPupop
    position absolute
    top 40px
    left 0
    width   100%
    height  100%
    background: rgba(0,0,0,.5)

.showRightIcon
    width   18px
    height  18px
    float: right;
    margin-top  21px
    margin-right: 21px
    img
        width   100%
        height 100%
.caseItem
    text-align: center;
    padding-top 8px
    color   #fff
    font-size: 15px;
    color: #FFFFFF;

.caseLists
    padding-top:40px;

</style>

<style type="text/css">
  .swiper_box{
    --swiper-pagination-color: #000;/* 两种都可以 */
  }
</style>
<style lang="stylus">

.showLeftPupopS
  width 225px
  padding-top 20px

.showLeftPupopItem
  height 53px
  line-height 53px
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  text-align: left;
  padding-left  50px
.actives
  width 100%
  background: #2878FF;
  box-sizing  border-box
  color: #fff
</style>