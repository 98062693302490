<template>
  <div class="mobileMain">
    <div class="heardNav">
        <div class="leftIcon" @click="show=true"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>
    <div class="productList">
        <div class="videoOrImg">
            <!-- <div class="showTitleName">产品一</div> -->
            <div class="showTitleTwo">安全数智管理平台</div>
            <div class="showTitleTwoDec">提供企业级项目施工全过程安全数智化监管服务，包含工程信息数智化，施工计划管理，标准安全防护措施，风险动态管控及预警，
                现场人员/机械设备及车辆管控等，全方位提升项目工程安全信息化。</div>
            <div class="showTitleImg"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/device.png" alt=""></div>
        </div>

        <div class="videoOrImg">
            <!-- <div class="showTitleName">产品二</div> -->
            <div class="showTitleTwo">双重预防管理平台</div>
            <div class="showTitleTwoDec">结合施工计划主动识别风险并及时预警，风险处理落实到人，闭环流程处理机制。形成电子档案及汇报材料，实现管理留痕可追溯。
                系统内置8000余条隐患处理措施，保障处理人员快速妥善进行问题处理。实现安全隐患治理“信息化” “可视化” “数字化” “清单化” “闭环化” 。</div>
            <div class="showTitleImg"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/url.png" alt=""></div>
        </div>

        <div class="videoOrImg">
            <!-- <div class="showTitleName">产品三</div> -->
            <div class="showTitleTwo">人员管理及教育平台</div>
            <div class="showTitleTwoDec">人员管理结合安全培训，以信息数字化手段实现对施工人员的日常工作管控，将安全生产落实到每一名一线作业人员。系统内置庞大的视频课件及试卷库，
                包含三级教育，班前会，日常安全培训，覆盖各类工种。所有人员信息，学习及考试台账均可下载，线下归档留存。</div>
            <div class="showTitleImg"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/urls.png" alt=""></div>
        </div>
    </div>
    <van-popup v-model="show" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupop">
        <div class="showLeftPupopItem " @click="getUrl(0)">首页</div>
        <div class="showLeftPupopItem actives" >产品</div>
        <div class="showLeftPupopItem " @click="getUrl(2)">新媒体</div>
        <div class="showLeftPupopItem " @click="getUrl(3)">渠道加盟</div>
        <div class="showLeftPupopItem " @click="getUrl(4)">关于我们</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// var _czc = _czc || [];
// //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
// _czc.push(["_setAccount", "1280801298"]);
export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
        return{
          pupop:false,
          volume:0,
          show:false,
          showVideoStatus:false
        }
  },

  methods:{
    showVideo(){
      this.showVideoStatus=!this.showVideoStatus
    },
    getUrl(e){
      if(e==0){
        this.$router.push({name:'Home'})
      }
      if(e==2){
        this.$router.push({name:'m_video'})
      }
      if(e==3){
        this.$router.push({name:'m_join'})
      }
      if(e==4){
        this.$router.push({name:'m_aboutus'})
      }
    },
    
  },
  mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      let clientWidths= document.documentElement.clientWidth
      if(clientWidths>900){
         that.$router.push({name:'Home',params:{type:2}})
        }
    
      }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            setRem();
        };
        setRem();
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.heardNav
  display flex
  justify-content flex-start
  align-items center
  padding 15px 25px
  position absolute
  top 0
  left: 0
  background #fff
  width 100%
  box-sizing  border-box
  z-index 99
  .leftIcon
    padding-right 24px
    img
      width 20px
      height  20px
      vertical-align: middle
  .rightName
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 2px;

.productList
    padding 20px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left
    img
        width   100%

.showTitleName
    font-size: 24px;
    font-weight 600
    color: #333333;
.videoOrImg
    padding-top 50px
.showTitleTwo
    font-size: 18px;
    color: #333333;
    padding-top 6px
    font-weight: 600

.showTitleTwoDec
    padding 15px 0
    font-size: 14px;
    color: #666666;
    line-height 40px


</style>
